<template>
    <form @submit.prevent="applyFilters()">
        <div class="filter-row date-and-search-filters">
            <div class="date-picker-column">
                <div class="date-picker-column__from">
                    <label for="from" class="picker-form-label">From:</label>
                    <VueDatePicker
                        @update:model-value="applyFilters"
                        v-model="dateFrom"
                        class="picker-input"
                        :enable-time-picker="false"
                        auto-apply>
                    </VueDatePicker>
                </div>
                <div class="date-picker-column__to">
                    <label for="from" class="picker-form-label">To:</label>
                    <VueDatePicker
                        @update:model-value="applyFilters"
                        v-model="dateTo"
                        class="picker-input"
                        :enable-time-picker="false"
                        auto-apply>
                    </VueDatePicker>
                </div>
            </div>
            <div class="search-column filter-search">
                <div class="filter-search__search-icon">
                    <button type="submit">
                        <img src="theme/luxoft/assets/images/icons/search-grey.svg" alt="search icon" />
                    </button>
                </div>
                <input type="text" name="search" class="filter-search__search-input" v-model.trim="searchWord" placeholder="Search">
            </div>
            <a class="reset-column reset-btn" href="#" @click.prevent="clearFilters()">
                <img
                    src="theme/luxoft/assets/images/icons/remove-black.svg"
                    class="reset-btn__image"
                    alt="Reset filter"
                >
                Reset all filters
            </a>
        </div>
    </form>

    <div class="news-list__content vue-filter-result-container">
        <div v-if="contentTypeItems.length < 1 && !isLoading" class="row">
            <div class="col-12">
                <div class="error-container">
                    <h5>No results were found.</h5>
                </div>
            </div>
        </div>
        <div v-if="isThereAnError" class="row">
            <div class="col-12">
                <div class="error-container">
                    <img src="theme/luxoft/assets/images/icons/circle-exclamation-solid.svg" class="error-icon" alt="error icon">
                    <h5>Something went wrong, please try again.</h5>
                </div>
            </div>
        </div>
        <div v-if="isLoading && !isThereAnError" class="row">
            <span class="loading-spinner"></span>
        </div>
        <SinglePr
            v-for="pr in filteredItems"
            :pr="pr"
            :key="pr.id"
        />
    </div>

    <div class="row vue-filter-result-container">
        <div class="prefix" v-if="!isLoading && !isThereAnError && contentTypeItems.length > 0">
            <div class="col">
                <vue-awesome-paginate
                    :total-items="totalItems"
                    :items-per-page="itemsPerPage"
                    :max-pages-shown="4"
                    v-model="currentPage"
                    :on-click="onClickHandler"
                    :show-breakpoint-buttons="true"
                    class="pagination custom-paginator justify-content-center"
                />
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, reactive, computed, nextTick } from 'vue'
import SinglePr from '../components/SinglePr.vue'
import VueDatePicker from '@vuepic/vue-datepicker'
import '@vuepic/vue-datepicker/dist/main.css'

const totalItems = ref(0)
const currentPage = ref(1)
const itemsPerPage = 6
const contentTypeItems = ref([])
const loadedPages = ref([])
const isLoading = ref(null)
const isThereAnError = ref(null)
const searchWord = ref(null)
const dateFrom = ref(null)
const dateTo = ref(null)
const jumpedPages = ref([])
const scrollDiv = document.getElementById('contenttype-with-filters').offsetTop - 200

const filteredItems = computed( () => {
    const values = contentTypeItems.value.slice((currentPage.value - 1) * itemsPerPage, currentPage.value * itemsPerPage)
    if (values[0]?.id !== undefined) return values
})

const onClickHandler = (page) => {
    const paginationClicked = true
    fetchData(page, paginationClicked)
}

async function applyFilters () {
    await nextTick()
    currentPage.value = 1
    loadedPages.value = []
    contentTypeItems.value = []
    await fetchDataCall(currentPage.value, true)
}

const fetchData = (page = 1, paginationClicked = false) => {
    if (!loadedPages.value.includes(page)) {
        const lastLoadedPage = loadedPages.value[loadedPages.value.length - 1]

        /**
         * If the users click on a page bigger than the next one
         * From page 1 to 3 for instance
         */
        if (page > (lastLoadedPage + 1) && page !== 1) {
            let jumpPage = lastLoadedPage + 1

            while (jumpPage < page)  {
                if (!loadedPages.value.includes(jumpPage)) {
                    jumpedPages.value.push(jumpPage)
                    contentTypeItems.value.push({}, {}, {}, {}, {}, {})
                }
                jumpPage++
            }
        }

        currentPage.page = page
        fetchDataCall(page, paginationClicked)
    }
}

const fetchDataCall = async (page = 1, paginationClicked = false) => {
    try  {
        isThereAnError.value = false
        isLoading.value = true
        const resultContainer = document.querySelector('.news-list__content')

        if (resultContainer) {
            resultContainer.style.height = `${resultContainer.offsetHeight}px`
        }

        if (paginationClicked) {
            window.scrollTo({ top: scrollDiv, behavior: 'smooth'})
        }

        const response = await fetch('/ajax/filter-content-type', {
            method: 'POST',
            body: JSON.stringify({
                search: searchWord.value,
                content_type: ['pr'],
                page: currentPage.value,
                from: dateFrom.value,
                to: dateTo.value,
                records_per_page: itemsPerPage
            })
        })
        const data = await response.json()

        totalItems.value = data.pagination.total_items
        loadedPages.value.push(page)

        if (jumpedPages.value.includes(page)) {
            jumpedPages.value = jumpedPages.value.filter((item) => item !== page)
            contentTypeItems.value.splice((currentPage.value - 1) * itemsPerPage, itemsPerPage, ...data.items)
        } else {
            contentTypeItems.value = [...contentTypeItems.value, ...data.items]
        }

        if (resultContainer) {
            resultContainer.style.height = 'auto'
        }

        isLoading.value = false

    } catch (error) {
        isThereAnError.value = true
        console.log(error)
    }
}

const clearFilters = () => {
    dateFrom.value = null
    dateTo.value = null
    searchWord.value = null
    loadedPages.value = []
    contentTypeItems.value = []
    currentPage.value = 1
    fetchDataCall()
}

fetchData()
</script>
