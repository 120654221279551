const links = document.querySelectorAll('.btn.btn-text.btn-icon.focus');
links.forEach(link => {
    const href = link.getAttribute('href')
    if (!href || !href.startsWith('#') || href.length <= 1) {
        return ;
    }
    const section = document.getElementsByClassName(href.substring(1));
    if (!section.length) {
        return ;
    }
    section[0].setAttribute('id', href.substring(1));
});