<template>
    <div class="container">
        <div class="row">
            <div class="col-12 col-lg-2 filter-container">
                <div class="embedded-filter-search">
                    <div class="embedded-filter-search__search-icon">
                        <button type="submit">
                            <img src="/theme/luxoft/assets/images/icons/search.svg" alt="search icon"/>
                        </button>
                    </div>
                    <form @submit.prevent="applyFilters()" class="embedded-filter-search__form">
                        <input type="text" name="search" v-model.trim="searchWord" class="form-control embedded-filter-search__input" placeholder="Search">
                    </form>
                    <div v-show="searchWord" class="embedded-filter-search__cancel-icon">
                        <button type="button" @click="searchWord = null">
                            <img src="/theme/luxoft/assets/images/custom-pages/global-presence/close-icon.png" alt="close icon"/>
                        </button>
                    </div>
                    <div class="switch">
                        <button type="button" @click="mobileFiltersStatus = 'active'">
                            <img src="/theme/luxoft/assets/images/icons/filter.svg" alt="filter icon"/>
                            Filters
                        </button>
                    </div>
                </div>
                <div class="filters-col" :class="mobileFiltersStatus">
                    <div class="filters-col__cancel-icon">
                        <button type="button" @click="mobileFiltersStatus = ''">
                            <img src="/theme/luxoft/assets/images/custom-pages/global-presence/close-icon.png" alt="close icon"/>
                        </button>
                    </div>
                    <div class="filters-col__row insights-filters">
                        <p class="filters-col__row__title">Industries</p>
                        <div class="filters-col__row__list insights-filters">
                            <button
                                v-for="(item, key) in industriesList"
                                :key="key"
                                class="sigle-filter-btn fw-normal"
                                :class="getButtonClass('industry', key)"
                                @click.prevent="applyFilters('industry', key)"
                                type="button"
                            >
                                {{ item }}
                            </button>
                        </div>
                    </div>
                    <div class="filters-col__row insights-filters">
                        <p class="filters-col__row__title">Digital Transformation solutions</p>
                        <div class="filters-col__row__list insights-filters">
                            <button
                                v-for="(item, key) in servicesFiltered"
                                :key="key"
                                class="sigle-filter-btn fw-normal"
                                :class="getButtonClass('service', key)"
                                @click.prevent="applyFilters('service', key)"
                                type="button"
                            >
                                {{ item }}
                            </button>
                        </div>
                    </div>
                    <div class="filters-col__row insights-filters">
                        <p class="filters-col__row__title">Content types</p>
                        <div class="filters-col__row__list insights-filters">
                            <button
                                class="sigle-filter-btn fw-normal"
                                @click.prevent="applyFilters('content-type', 'whitepapers')"
                                :class="getButtonClass('content-type', 'whitepapers')"
                                type="button"
                            >
                                White paper
                            </button>
                            <button
                                class="sigle-filter-btn fw-normal"
                                @click.prevent="applyFilters('content-type', 'blog')"
                                :class="getButtonClass('content-type', 'blog')"
                                type="button"
                            >
                                Blog post
                            </button>
                            <button
                                class="sigle-filter-btn fw-normal"
                                @click.prevent="applyFilters('content-type', 'videos')"
                                :class="getButtonClass('content-type', 'video')"
                                type="button"
                            >
                                Video
                            </button>
                            <button
                                class="sigle-filter-btn fw-normal"
                                @click.prevent="applyFilters('content-type', 'case-studies')"
                                :class="getButtonClass('content-type', 'case-studies')"
                                type="button"
                            >
                                Case study
                            </button>
                        </div>
                    </div>
                    <div v-if="selectedContentType.length > 0 || selectedServices.length > 0 || selectedIndustries.length > 0" class="filters-col__row insights-filters">
                        <button class="clear-insights-filters" @click.prevent="clearFilters()">
                            Clear all <img src="/theme/luxoft/assets/images/custom-pages/global-presence/close-icon.png" alt="close icon"/>
                        </button>
                    </div>
                </div>
            </div>
            <div class="col-12 col-lg-10 vue-filter-result-container">
                <div v-if="recommendedPost.length > 1 && !filtersUsed" class="insights-page__first-results">
                    <div class="row f-width section-title">
                        <div class="col-12">
                            <p>Recommended insights</p>
                        </div>
                    </div>
                    <template v-for="(insight, index) in recommendedPost">
                        <SingleInsightHub
                            v-if="insight !== undefined && insight?.fieldValues !== undefined"
                            :insight="insight"
                            :index="index"
                            :key="insight.id"
                            :type="'first'"
                            :source="'standard'"
                        />
                    </template>
                </div>

                <div v-if="firstLoadItems.length < 1 && !isLoading" class="d-inline-block w-100">
                    <div class="error-container">
                        <p><b>No results were found.</b></p>
                    </div>
                </div>

                <div class="insights-page__first-results">
                    <div v-if="firstLoadItems.length > 0" class="row f-width section-title d-inline-block" :class="{ 'mt-4': !filtersUsed }">
                        <div class="col-12">
                            <p>Latest insights</p>
                        </div>
                    </div>
                    <template v-for="(insight, index) in firstLoadItems">
                        <SingleInsightHub
                            v-if="insight !== undefined && insight?.fieldValues !== undefined"
                            :insight="insight"
                            :index="parseInt(index + 3)"
                            :key="insight.id"
                            :type="'first'"
                            :source="'standard'"
                        />
                    </template>
                </div>

                <div v-if="moreItemsLoaded.length > 0" class="insights-second-level__results">
                    <template v-for="(item) in moreItemsLoaded">
                        <template v-for="(element, key) in item">
                            <div
                                v-if="key.includes('items')"
                                class="insights-second-level__results--container"
                                :class="key"
                                :key="key"
                            >
                                <template v-for="(insight, index) in element">
                                    <SingleInsightHub
                                        v-if="insight !== undefined && insight?.fieldValues !== undefined"
                                        :insight="insight"
                                        :index="parseInt(index)"
                                        :type="'more'"
                                        :source="key"
                                        :key="index"
                                    />
                                </template>
                            </div>
                            <div
                                v-if="key.includes('people')"
                                class="insights-second-level__results--container people"
                                :key="key"
                            >
                                <template v-for="(person, index) in element">
                                    <SinglePersonInsight
                                        v-if="person !== undefined"
                                        :person="person"
                                        :key="index"
                                    />
                                </template>
                            </div>
                            <template v-if="key.includes('cta')">
                                <template v-for="(cta, index) in element">
                                    <CtaBanner
                                        v-if="cta === '1'"
                                        :key="index"
                                    >
                                        <template v-slot:subheading>
                                            Explore our network and technology solutions for software-defined organizations
                                        </template>
                                        <template v-slot:button>
                                            <a class="btn btn-text btn-icon btn-white" href="/industries/technology-networking">
                                                <div class="text-container">
                                                    <div class="text">
                                                        Learn more
                                                    </div>
                                                </div>
                                            </a>
                                        </template>
                                    </CtaBanner>
                                </template>
                            </template>
                        </template>
                    </template>
                </div>
                <div v-if="isThereAnError" class="row f-width">
                    <div class="col-12">
                        <div class="error-container">
                            <img src="/theme/luxoft/assets/images/icons/circle-exclamation-solid.svg" class="error-icon" alt="error icon">
                            <h5>Something went wrong, please try again.</h5>
                        </div>
                    </div>
                </div>
                <div v-if="isLoading && !isThereAnError" class="row f-width">
                    <span class="loading-spinner"></span>
                </div>
                <div v-if="!isLoading && !isThereAnError && (callSettings.page < totalPages || savedItems.length > 0)" class="row pt-4 f-width">
                    <div class="col text-center">
                        <button type="button" @click.prevent="onClickHandler()" class="btn btn-outline-primary event__btn">LOAD MORE</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, reactive, onMounted, computed } from 'vue'
import usefetchCall from '../composables/insightsFetch'
import SingleInsightHub from '../components/SingleInsightHub.vue'
import SinglePersonInsight from '../components/SinglePersonInsight.vue'
import CtaBanner from '../components/CtaBanner.vue'

const props = defineProps({
    recommendedPost: {
        type: Array,
        required: true,
        default: () => [],
    },
    industriesList: {
        type: Object,
        required: true,
        default: () => [],
    },
    servicesList: {
        type: Object,
        required: true,
        default: () => [],
    }
})

const { isThereAnError,
        isLoading,
        totalPages,
        firstLoadItems,
        moreItemsLoaded,
        fetchCall,
        loadMoreCounterClicked,
        savedItems,
        getRestOfSavedItems
    } = usefetchCall()

const searchWord = ref(null)
const allContentTypes = ['pr', 'videos', 'blog', 'case-studies', 'whitepapers']
const selectedIndustries = ref([])
const selectedServices = ref([])
const selectedContentType = ref([])
const mobileFiltersStatus = ref('')
const filtersUsed = ref(false)
const defaultIndustries = [
                            'healthcare',
                            'telecom',
                            'consumer-goods',
                            'energy-utilities',
                            'manufacturing',
                            'oil-gas',
                            'retail-ecommerce',
                            'travel-hospitality',
                            'transportation-logistics'
                        ]
const defaultServices = [
                        'data-analytics',
                        'bespoke-engineering',
                        'legacy-modernization',
                        'intelligent-automation',
                        'design',
                        'cloud',
                        'qa-automation'
                    ]

const callSettings = reactive({
                        "search": null,
                        "content_type": allContentTypes,
                        "industries": defaultIndustries,
                        "services": defaultServices,
                        "categories": [],
                        "page": 1,
                        "records_per_page": 8,
                    })

const servicesFiltered = computed(() => {
    const filteredEntries = Object.entries(props.servicesList).filter(([key, item]) => {
        return item !== 'Engineering Lab' && key !== 'engineering-lab';
    });
    return Object.fromEntries(filteredEntries);
});


const applyFilters = (type = null, value = null) => {
    if (type === 'industry') {
        if (selectedIndustries.value.includes(value)) {
            const indexIndustry = selectedIndustries.value.indexOf(value)
            if (indexIndustry !== -1) {
                selectedIndustries.value.splice(indexIndustry, 1)
            }
        } else {
            selectedIndustries.value.push(value)
        }
    }

    if (type === 'service') {
        if (selectedServices.value.includes(value)) {
            const indexIndustry = selectedServices.value.indexOf(value)
            if (indexIndustry !== -1) {
                selectedServices.value.splice(indexIndustry, 1)
            }
        } else {
            selectedServices.value.push(value)
        }
    }

    if (type === 'content-type') {
        if (selectedContentType.value.includes(value)) {
            const contentIndex = selectedContentType.value.indexOf(value)
            if (contentIndex !== -1) {
                selectedContentType.value.splice(contentIndex, 1)
            }
        } else {
            selectedContentType.value.push(value)
        }
    }

    callSettings.industries = (selectedIndustries.value.length > 0) ?
                                selectedIndustries.value :
                                (
                                    selectedIndustries.value.length < 1 &&
                                    selectedContentType.value.length > 0 &&
                                    selectedServices.value.length < 1
                                ) ? defaultIndustries : []

    callSettings.services =  (selectedServices.value.length > 0) ?
                                selectedServices.value :
                                (
                                    selectedServices.value.length < 1 &&
                                    selectedContentType.value.length > 0 &&
                                    selectedIndustries.value.length < 1
                                ) ? defaultServices : []

    if (
        selectedIndustries.value.length === 0 &&
        selectedServices.value.length === 0 &&
        selectedContentType.value.length === 0
    ) {
        callSettings.industries = defaultIndustries
        callSettings.services = defaultServices
        filtersUsed.value = false
    } else {
        filtersUsed.value = true
    }

    callSettings.content_type = (selectedContentType.value.length > 0) ? selectedContentType.value : allContentTypes
    mobileFiltersStatus.value = ''
    callSettings.page = 1
    callSettings.search = searchWord.value
    firstLoadItems.value = []
    moreItemsLoaded.value = []
    fetchData()
}

const onClickHandler = () => {
    callSettings.page++
    loadMoreCounterClicked.value++

    if (callSettings.page > totalPages.value) {
        getRestOfSavedItems()
    } else {
        fetchData(false)
    }
}

const fetchData = async (firstLoad = true) => {
    await fetchCall({ firstLoad, callSettings, extractItemsFromRes: true })
}

const clearFilters = () => {
    filtersUsed.value = false
    mobileFiltersStatus.value = ''
    searchWord.value = null
    selectedIndustries.value = []
    selectedServices.value = []
    selectedContentType.value = []
    firstLoadItems.value = []
    moreItemsLoaded.value = []
    callSettings.page = 1
    callSettings.search = null
    callSettings.industries = defaultIndustries
    callSettings.services = defaultServices
    callSettings.content_type = allContentTypes
    fetchData()
}

const getButtonClass = (type, value) => {
    if (
        type === 'industry' &&
        selectedIndustries.value.includes(value)
    ) {
        return 'active'
    }

    if (
        type === 'service' &&
        selectedServices.value.includes(value)
    ) {
        return 'active'
    }

    if (
        type === 'content-type' &&
        selectedContentType.value.includes(value)
    ) {
        return 'active'
    }

    return ''
}


onMounted(() => {
    fetchData()
})
</script>
