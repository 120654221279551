let sections = document.querySelectorAll(".container-why-luxoft");

sections.forEach((section) => {
  let cols = section.querySelectorAll(".col");

  cols.forEach((el) => {
    el.addEventListener("mouseenter", () => {
      setContent(el, section);
    });
    el.addEventListener("mouseleave", () => {
      removeContent(section);
    });
  });
});

function setContent(element, section) {
  section.querySelector(".why-info").innerHTML =
    element.firstElementChild.lastElementChild.innerHTML;
}

function removeContent(section) {
  section.querySelector(".why-info").innerHTML = "";
}

