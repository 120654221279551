const backToTop = document.getElementById('back-to-top-btn')

window.addEventListener('scroll', () => {
    if (window.pageYOffset >= 160 && !backToTop.classList.contains('shown')) {
        backToTop.classList.add('shown')
    }

    if (window.pageYOffset < 160 && backToTop.classList.contains('shown')) {
        backToTop.classList.remove('shown')
    }
})

backToTop.addEventListener('click', () => {
    window.scrollTo({top: 0, behavior: 'smooth'})
})