if (document.getElementById('partners-main')) {
    const filters = document.getElementsByClassName('partners-filter')

    /**
     * By default we display only the items from the first filter
     */
    filters[0].classList.add('filter-active')
    Array.from(document.querySelectorAll('.filtered')).forEach(
        (el) => {
            el.classList.remove('filtered')
            setTimeout(() => {
                el.classList.add('d-none')
            }, 250)
            
        }
    )

    setTimeout(() => {
        Array.from(document.querySelectorAll(`.${filters[0].id}`)).forEach(
            (el) => {
                el.classList.remove('d-none')
                setTimeout(() => {
                    el.classList.add('filtered')
                }, 100)
            }
        )
    }, 250)

    for (const filter of filters) {
        filter.addEventListener('click', e => {
            if (window.innerWidth > 903) {
                Array.from(document.querySelectorAll('.filter-active')).forEach(
                    (el) => el.classList.remove('filter-active')
                )

                filter.classList.add('filter-active')

                Array.from(document.querySelectorAll('.filtered')).forEach(
                    (el) => {
                        el.classList.remove('filtered')
                        setTimeout(() => {
                            el.classList.add('d-none')
                        }, 250)
                        
                    }
                )

                setTimeout(() => {
                    Array.from(document.querySelectorAll(`.${filter.id}`)).forEach(
                        (el) => {
                            el.classList.remove('d-none')
                            setTimeout(() => {
                                el.classList.add('filtered')
                            }, 100)
                        }
                    )
                }, 250)
            } else {
                Array.from(document.querySelectorAll('.displayed')).forEach(
                    (el) => el.classList.remove('displayed')
                )

                document.getElementById(`${filter.id}-filter-items`).classList.add('displayed')

                const scrollDiv = document.getElementById('partners-main').offsetTop
                window.scrollTo({ top: scrollDiv, behavior: 'smooth'})
            }
        })
    }

    if (window.innerWidth <= 903) {
        for (const filter of filters) {
            const firstFilterItemsContainer = document.getElementById(`${filter.id}-filter-items`)
            let htmlContent = ``
    
            Array.from(document.querySelectorAll(`.${filter.id}`)).forEach(
                (el) => htmlContent += el.innerHTML
            )
            
            firstFilterItemsContainer.innerHTML = htmlContent
        }

        document.getElementById(`${filters[0].id}-filter-items`).classList.add('displayed')
    }
}