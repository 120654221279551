document.querySelectorAll('.team-slider').forEach(slider => {
  let count = slider.querySelectorAll('.team-slider').length;

  if (count < 3) {
    tns({
      container: slider,
      items: 2,
      slideBy: 'page',
      autoplay: false,
      loop: false,
      navPosition: 'bottom',
      disable: false,
      responsive: {
        600: {
          disable: true
        }
      }
    });
  }
});
