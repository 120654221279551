document.getElementById('global-search').addEventListener('submit', (e) => {
    if (document.getElementById('input-search').value.trim() === '') {
        e.preventDefault()   
    }
})

const globalSearchBtn = document.getElementById('global-search-404')

if (globalSearchBtn) {
    globalSearchBtn.addEventListener('submit', (e) => {
        if (document.getElementById('input-search-404').value.trim() === '') {
            e.preventDefault()   
        }
    })
}