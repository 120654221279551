if (document.getElementsByClassName('contact_us--tabs').length > 0) {
  // show tab if it is passed as a #hash in url
  const hash = $(location).attr('hash');
  const selector = 'a[href="' + hash + '"]';
  const msgCategory = $('#contact_form_message_category option[value="' + hash.slice(1) + '"]');

  if ($(selector) && $(selector).hasClass('nav-link')) {
    if (hash && hash.includes('careers')) {
      $('#tab-B, #tab-A').toggleClass('active')
      $('#careers, #pane-A').toggleClass('active show')
    }
  } else if (msgCategory && hash && hash.slice(1) === 'copyrights') {
    $(msgCategory).attr('selected', hash.slice(1));
  } else if (msgCategory && hash) {
    // $('#tab-B, #tab-A').toggleClass('active')
    // $('#pane-B, #pane-A').toggleClass('active show')
    $(msgCategory).attr('selected', 'selected');
  }
}

$(".explanatory-notes-btn").click(function(e){
  e.preventDefault()
  $(this).toggleClass('expanded')
  $(this).next().slideToggle(400)
})

if (document.getElementById('contact_form_message_category')) {
  document.getElementById('contact_form_message_category').addEventListener('change', function() {
    sessionStorage.setItem('message_category', this.value)
  })
}

if (
    window.location.pathname === '/contact-form' &&
    window.location.href.includes('formresult=contactus')
  ) {
  const contactMessageCategory = sessionStorage.getItem('message_category')
  window.dataLayer = window.dataLayer || []
  window.dataLayer.push ({"event": "page-load", "contactMessageCategory": contactMessageCategory});
}

  $( document ).ready(function() {
    let messageField = $("#contact_form_details")
    function messageFormatter () {
      if ($("#form-details-error")[0]) {
        let counterValue = messageField[0].value.length
        let validationValue = 50 - counterValue
        $(".details-counter")[0].innerHTML = counterValue + ' characters (min: 50)'


        if ($("#form-details-error")[0].firstElementChild) {
          if (validationValue <= 0) {
            $("#form-details-error")[0].firstElementChild.innerHTML = ""
            $(this)[0].classList.remove('is-invalid')
          } else {
            $("#form-details-error")[0].firstElementChild.innerHTML = `This message is too short. Please enter at least ${validationValue} more characters.`
            $(this)[0].classList.add('is-invalid')
          }
        }
      }
    }
  messageField.keyup(messageFormatter)
  messageFormatter()
})
