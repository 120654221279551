if (
  document.getElementsByClassName('s-list').length > 0 &&
  document.getElementsByClassName('s-list-item-body').length > 0
  )  {
  const sListItems = document.getElementsByClassName('s-list-item-body')

  for (const sListItem of sListItems) {
    sListItem.classList.add('collapsed')

    sListItem.addEventListener('click', () => {
      if (window.innerWidth <= 1438) {
        sListItem.classList.toggle('collapsed')
      }
    })
  }
}

$( document ).ready(function() {
  var contentItems = $('.client-story').find('.content > .content-item');
  var activeTabIndex = $('.client-story').find('.tabs > .active').data('content-item-to');
  $(contentItems[activeTabIndex]).fadeIn(200);
});

function tabActivate(tab) {
  var activeTab = $(tab).parent().find('.active');
  var currentTabIndex = $(tab).data('content-item-to');
  var tabsContent = $(tab).parent().parent().find('.content > .content-item');

  $(activeTab).removeClass('active');
  $(tab).addClass('active');

  $(tabsContent).fadeOut(1);
  $(tabsContent[currentTabIndex]).delay(300).fadeIn(200);
}

$('.client-story').on('click', '.tab', function () {tabActivate(this)});
$('.client-story').on('click', '.btn-container', function () {
  var tabActive = $(this).parent().parent().parent().find('.tabs > .active');
  var tabLast = $(this).parent().parent().parent().find('.tabs > .tab').last();
  var tabNext = $(tabActive).data('content-item-to') !== $(tabLast).data('content-item-to')
    ? tabActive.next()
    : tabActive.parent().find('.tab').first();

  tabActivate(tabNext);
});

$('video').parent().click(function () {
  if($(this).children("video").get(0).paused){
    $(this).children("video").get(0).play();
    $(this).children(".play").fadeOut();
  }else{
    $(this).children("video").get(0).pause();
    $(this).children(".play").fadeIn();
  }
});

if (document.querySelectorAll('.container-key-points').length > 0) {
  const keyPoints = document.querySelectorAll('.container-key-points')

  for (keypoint of keyPoints) {
    for (row of keypoint.querySelectorAll('.row')) {
      for (col of row.querySelectorAll('.col')) {
        col.classList.add(`key-items-${row.children.length}`)

        for (tag of col.children) {
          if (tag.tagName === 'H5') {
            col.classList.add('with-h5')
            break
          }
        }
      }
    }
  }
}

const openSeachBtn = document.getElementById('search-btn')
const closeSeachBtn = document.getElementById('close-search')
const headerSquare = document.getElementById('header--square')
const inputSearch = document.getElementById('input-search')
const searchInputWrapper = document.querySelector('.header__search-open')
const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]')
const tooltipList = [...tooltipTriggerList].map(tooltipTriggerEl => new bootstrap.Tooltip(tooltipTriggerEl))

openSeachBtn.addEventListener('click', (e) => {
  e.preventDefault()

  openSeachBtn.classList.add('active')
  searchInputWrapper.classList.add('active')
  headerSquare.classList.add('show')
  inputSearch.focus()
  setTimeout(() => {
    searchInputWrapper.style.opacity = 1
  }, 10)
})

closeSeachBtn.addEventListener('click', (e) => {
  e.preventDefault()

  searchInputWrapper.style.opacity = 0
  openSeachBtn.classList.remove('active')
  headerSquare.classList.remove('show')
  setTimeout(() => {
    searchInputWrapper.classList.remove('active')
  }, 300)
})

if (document.getElementsByClassName('solutions').length > 0) {
  const solutionsSection = document.getElementsByClassName('solutions')

  for (const solutions of solutionsSection) {
    if (solutions.getElementsByClassName('carousel slide').length > 0) {
      const carouselSlider = solutions.getElementsByClassName('carousel slide')
      const controls = carouselSlider[0].getElementsByClassName('carousel-indicators')

      carouselSlider[0].addEventListener('mouseleave', function (e) {
        const activeBtn = controls[0].getElementsByClassName('active')[0]
        activeBtn.classList.add('paused')

        setTimeout(() => {
          activeBtn.classList.remove('paused')
        }, 1000)
      })
    }
  }
}
