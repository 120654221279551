<template>
    <div class="col-12 col-md-6 col-lg-4 col-xl-3 mb-3">
        <a :href="event.extras.link" class="event__link">
            <div class="card event__card" >
                <div class="card-body">
                    <p class="card-title event__body--title"> {{ event.fieldValues.title.en }}</p>
                    <p class="card-text"> {{ event.fieldValues.full_date === "" ? applyFormat(event.fieldValues.end_date) : event.fieldValues.full_date }} </p>
                </div>
                <div class="card-footer event__card--footer">
                    <p class="card-text">{{ event.fieldValues.location }}</p>
                </div>
            </div>
        </a>
    </div>
</template>

<script setup>
import formatDate from '../composables/formatDate'

const props = defineProps({
    event: {
        type: Object,
        required: true,
        default: () => [],
    }
})

const applyFormat = formatDate()
    
</script>