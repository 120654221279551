<template>
    <div class="col-12 col-lg-6">
        <a :href="video.extras.link">
            <div class="card border-0 mb-3 myvideos__card">
                <div class="card">
                <div class="play"></div>
                <img 
                    class="myvideos__image-video" 
                    :src="(video.fieldValues.photo.extension === '') ? 'https://dummyimage.com/700x420/000/fff' : video.fieldValues.photo.path"
                    :alt="video.fieldValues.photo.alt + ' | ' + video.extras.title"
                >
                </div>
                <div class="card-body">
                <h5 class="myvideos__card-title">{{ video.extras.title }}</h5>
                <p class="myvideos__card-text">{{ video.extras.teaser }} </p>
                </div>
                <div class="myvideos__card-date">
                <p>{{ applyFormat(video.publishedAt) }}</p>
                </div>
            </div>
        </a>
    </div>
</template>

<script setup>
import formatDate from '../composables/formatDate'

const props = defineProps({
    video: {
        type: Object,
        required: true,
        default: () => [],
    }
})

const applyFormat = formatDate()
</script>