<template>
    <div class="single-person">
        <div class="single-person--image">
            <img :src="person.fieldValues.image.path" :alt="`${person.fieldValues.name}, ${person.fieldValues.title.en}`">
        </div>
        <div class="single-person--details">
            <h5 class="text-white name">
                {{ person.fieldValues.name }}
            </h5>
            <p class="text-white job-title">
                {{ person.fieldValues.title.en }}
            </p>
        </div>
        <div class="single-person--btn">
            <a class="btn btn-text btn-icon focus" :href="person.fieldValues.linkedin_url">
                <div class="text-container">
                    <div class="text stretched-link">Contact us</div>
                    <div class="arr-offering">
                        <i class="arr-small one"></i>
                        <i class="arr-small two"></i>
                        <i class="arr-small three"></i>
                    </div>
                </div>
            </a>
        </div>
        <div class="single-person--linkedin">
            <img src="https://www.luxoft.com/theme/luxoft/assets/images/icons/linkedin.svg" alt="linkedin icon">
        </div>
    </div>
</template>

<script setup>
const props = defineProps({
    person: {
        type: Object,
        required: true,
        default: () => [],
    },
})
</script>