const collapsibleBtn = document.querySelector('.collapsible-read-more .btn-container a')

if (collapsibleBtn) {
  const collapsibleBtnText = collapsibleBtn.querySelector('.text-container .text')
  let collapsed = true
  
  collapsibleBtn.addEventListener("click", function () {
    collapsed = !collapsed
    collapsibleBtnText.textContent = collapsed ? "read more" : "read less"
  })
}

