const socialBtns = document.getElementsByClassName('social-share')

for (const btn of socialBtns) {
    btn.addEventListener('click', e => {
        e.preventDefault()

        let socialLink;
        const title = document.getElementsByTagName('title')[0]

        if (btn.dataset.network === 'fb') {
            socialLink = `http://www.facebook.com/share.php?title=${encodeURIComponent(title.textContent.trim())}&u=`
            window.open(socialLink+encodeURIComponent(window.location.href),'window','width=640,height=480,resizable,scrollbars')
        }

        if (btn.dataset.network === 'twitter') {
            socialLink = `https://twitter.com/intent/tweet?text=${encodeURIComponent(title.textContent.trim())}&url=`
            window.open(socialLink+encodeURIComponent(window.location.href),'window','width=640,height=480,resizable,scrollbars')
        }

        if (btn.dataset.network === 'linkedin') {
            socialLink = `https://www.linkedin.com/sharing/share-offsite/?url=${window.location.href}`
            window.open(socialLink,'window','width=640,height=480,resizable,scrollbars')
        }
    })
}

window.fbAsyncInit = function() {
    FB.init({
        appId            : 2199632733671618,
        autoLogAppEvents : true,
        xfbml            : true,
        version          : 'v15.0'
    })
}

(function(d, s, id){
    var js, fjs = d.getElementsByTagName(s)[0];
    if (d.getElementById(id)) {return;}
    js = d.createElement(s); js.id = id;
    js.src = "//connect.facebook.net/en_US/sdk.js";
    fjs.parentNode.insertBefore(js, fjs);
}(document, 'script', 'facebook-jssdk'))
