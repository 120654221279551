const servicesVerticalButtons = document.querySelectorAll('.services-vertical-tabs__main-container--tabs button')

if (servicesVerticalButtons.length > 0) {
    for (const btn of servicesVerticalButtons) {
        btn.addEventListener('click', e => {
            if (window.innerWidth <= 903 && btn.dataset.href) {
                e.preventDefault()
                location.href = btn.dataset.href
            }
        })
    }
}