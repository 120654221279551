const images = document.getElementsByClassName('lazy-loading')

function loadLazyImages(){
  const observer = new IntersectionObserver(function(entries, observer){
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        const lazyImg = entry.target
        lazyImg.src = lazyImg.dataset.src
        lazyImg.classList.remove('lazy-image')
        observer.unobserve(lazyImg)
      }
    })
  })

  for (const img of images) {
    if (!img.classList.contains('benefits-main-alt')) {
      img.classList.add('lazy-image')
      observer.observe(img) 
    }
  }
}

loadLazyImages()

