<template>
    <div>
        <hr class="division-line">
        <div class="single-pr-row">
            <div class="date-col">
                <p>{{ applyFormat(pr.publishedAt) }}</p>
            </div>
            <div class="body-content">
                <p class="body-content__title" v-html="pr.fieldValues.title.en" />
                <p class="body-content__teaser">{{ pr.fieldValues.preview_description_text }}</p>
                <div>
                    <a class="btn btn-text btn-icon focus" :href="pr.extras.link">
                        <div class="text-container">
                            <div class="text">READ MORE</div>
                            <div class="arr-offering">
                                <i class="arr-small one"></i>
                                <i class="arr-small two"></i>
                                <i class="arr-small three"></i>
                            </div>
                        </div>
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import formatDate from '../composables/formatDate'

const props = defineProps({
    pr: {
        type: Object,
        required: true,
        default: () => [],
    }
})

const applyFormat = formatDate()
</script>
