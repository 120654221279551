<template>
    <div class="col-12 col-md-6 col-lg-4 col-xl-3">
        <a :href="event.extras.link" class="event__link">
            <div class="card event__image">
                <img class="rounded" :src="(event.fieldValues.photo.extension !== '') ? event.fieldValues.photo.path:'https://dummyimage.com/600x224/000/fff' " alt="Card image cap">
                <div class="card-body event__body">
                    <p class="card-title event__body--title">{{ event.fieldValues.title.en }}</p>
                    <div class="d-flex event__body--date">
                        <p> {{ event.fieldValues.full_date === '' ? applyFormat(event.fieldValues.end_date) : event.fieldValues.full_date }}</p>
                        <span v-if="event.fieldValues.type === 'in-person' || event.fieldValues.type === 'in-person event'" class="mx-1">|</span>
                        <p v-if="event.fieldValues.type === 'in-person' || event.fieldValues.type === 'in-person event'">{{ event.fieldValues.location }}</p>
                    </div>
                </div>
            </div>
        </a>
    </div>
</template>

<script setup>
import formatDate from '../composables/formatDate'

const props = defineProps({
    event: {
        type: Object,
        required: true,
        default: () => [],
    }
})

const applyFormat = formatDate()
    
</script>