<template>
    <div class="row">
        <div class="col-12 col-lg-3 filter-container">
            <form @submit.prevent="applyFilters()">
                <div class="embedded-filter-search">
                    <div class="embedded-filter-search__search-icon">
                        <button type="submit">
                            <img src="/theme/luxoft/assets/images/icons/search.svg" alt="search icon"/>
                        </button>
                    </div>
                    <input type="text" name="search" v-model.trim="searchWord" class="form-control embedded-filter-search__input" placeholder="Search">
                    <div v-show="searchWord" class="embedded-filter-search__cancel-icon">
                        <button type="button" @click="searchWord = null">
                            <img src="/theme/luxoft/assets/images/custom-pages/global-presence/close-icon.png" alt="close icon"/>
                        </button>
                    </div>
                    <button type="button" class="clear-btn" @click="clearFilters()">
                        Clear filters
                    </button>
                    <div class="switch">
                        <button type="button" @click="mobileFiltersStatus = 'active'">
                            <img src="/theme/luxoft/assets/images/icons/filter.svg" alt="filter icon"/>
                            Filters
                        </button>
                    </div>
                </div>
                <div class="filters-col" :class="mobileFiltersStatus">
                    <div class="filters-col__cancel-icon">
                        <button type="button" @click="mobileFiltersStatus = ''">
                            <img src="/theme/luxoft/assets/images/custom-pages/global-presence/close-icon.png" alt="close icon"/>
                        </button>
                    </div>
                    <div class="filters-col__row">
                        <p class="filters-col__row__title">Filter by industry</p>
                        <div class="filters-col__row__list regular-with-checkbox">
                            <label v-for="(item, key) in industriesList" :key="key" class="item-container">
                                <input type="checkbox" :value="key" v-model="selectedIndustries">
                                <span class="checkmark">{{ item }}</span>
                            </label>
                        </div>
                    </div>
                    <div class="filters-col__row">
                        <p class="filters-col__row__title">Pick relevant topic</p>
                        <div class="filters-col__row__list regular-with-checkbox">
                            <label v-for="(item, key) in servicesList" :key="key" class="item-container">
                                <input v-if="item !== 'Engineering Lab'" type="checkbox" :value="key" v-model="selectedServices">
                                <span v-if="item !== 'Engineering Lab'" class="checkmark">{{ item }}</span>
                            </label>
                        </div>
                        <div class="filters-col__row__controls">
                            <button class="btn btn-filter" type="submit">Apply</button>
                            <a href="#" class="btn-reset" @click.prevent="clearFilters()">Reset</a>
                        </div>
                    </div>
                </div>
            </form>
        </div>
        <div class="col-12 col-lg-9 vue-filter-result-container">
            <div v-if="contentTypeItems.length < 1 && !isLoading" class="row">
                <div class="col-12">
                    <div class="error-container">
                        <h5>No results were found.</h5>
                    </div>
                </div>
            </div>
            <div v-if="isThereAnError" class="row">
                <div class="col-12">
                    <div class="error-container">
                        <img src="/theme/luxoft/assets/images/icons/circle-exclamation-solid.svg" class="error-icon" alt="error icon">
                        <h5>Something went wrong, please try again.</h5>
                    </div>
                </div>
            </div>
            <div v-if="isLoading && !isThereAnError" class="row">
                <span class="loading-spinner"></span>
            </div>
            <div class="row" v-if="contentType === 'case-studies'">
                <template v-for="caseStudy in filteredItems">
                    <SingleCaseStudy
                        v-if="caseStudy && caseStudy?.fieldValues !== undefined"
                        :caseStudy="caseStudy"
                        :key="caseStudy.id"
                    />
                </template>
            </div>
            <div class="row" v-if="contentType === 'videos'">
                <template v-for="video in filteredItems">
                    <SingleVideo
                        v-if="video && video?.fieldValues !== undefined"
                        :video="video"
                        :key="video.id"
                    />
                </template>
            </div>
            <div class="row" v-if="contentType === 'blog'">
                <template v-for="blog in filteredItems">
                    <SingleBlog
                        v-if="blog && blog?.fieldValues !== undefined"
                        :blog="blog"
                        :key="blog.id"
                    />
                </template>
            </div>

            <div class="row prefix" v-if="!isLoading && !isThereAnError && contentTypeItems.length > 0">
                <div class="col">
                    <vue-awesome-paginate
                        :total-items="totalItems"
                        :items-per-page="itemsPerPage"
                        :max-pages-shown="4"
                        v-model="currentPage"
                        :on-click="onClickHandler"
                        :show-breakpoint-buttons="true"
                        class="pagination custom-paginator justify-content-center"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue'
import SingleCaseStudy from '../components/SingleCaseStudy.vue'
import SingleVideo from '../components/SingleVideo.vue'
import SingleBlog from '../components/SingleBlog.vue'

const props = defineProps({
    industriesList: {
        type: Object,
        required: true,
        default: () => [],
    },
    servicesList: {
        type: Object,
        required: true,
        default: () => [],
    }
})

const totalItems = ref(0)
const currentPage = ref(1)
const itemsPerPage = 6
const contentTypeItems = ref([])
const loadedPages = ref([])
const selectedIndustries = ref([])
const selectedServices = ref([])
const mobileFiltersStatus = ref('')
const isLoading = ref(null)
const isThereAnError = ref(null)
const contentType = ref(null)
const searchWord = ref(null)
const jumpedPages = ref([])
const scrollDiv = document.getElementById('contenttype-with-filters').offsetTop - 200

switch (window.location.pathname) {
    case '/case-studies':
        contentType.value = 'case-studies'
        break;
    case '/blog':
        contentType.value = 'blog'
        break;
    case '/videos':
        contentType.value = 'videos'
        break;
}

const filteredItems = computed( () => {
    const values = contentTypeItems.value.slice((currentPage.value - 1) * itemsPerPage, currentPage.value * itemsPerPage)
    if (values[0]?.id !== undefined) return values
})

const onClickHandler = (page) => {
    const paginationClicked = true
    fetchData(page, paginationClicked)
}

const applyFilters = () => {
    mobileFiltersStatus.value = ''
    currentPage.value = 1
    loadedPages.value = []
    contentTypeItems.value = []
    fetchCallActions(currentPage.value, true)
}

const fetchData = (page = 1, paginationClicked = false) => {
    if (!loadedPages.value.includes(page)) {
        const lastLoadedPage = loadedPages.value[loadedPages.value.length - 1]

        /**
         * If the users click on a page bigger than the next one
         * From page 1 to 3 for instance
         */
        if (page > (lastLoadedPage + 1) && page !== 1) {
            let jumpPage = lastLoadedPage + 1

            while (jumpPage < page)  {
                if (!loadedPages.value.includes(jumpPage)) {
                    jumpedPages.value.push(jumpPage)
                    contentTypeItems.value.push({}, {}, {}, {}, {}, {})
                }
                jumpPage++
            }
        }

        currentPage.value = page
        fetchCallActions(page, paginationClicked)
    }
}

const fetchCallActions = async (page = 1, paginationClicked = false) => {
    try  {
        isThereAnError.value = false
        isLoading.value = true
        const resultContainer = document.querySelector('.contenttype-with-filters .vue-filter-result-container')

        if (resultContainer) {
            resultContainer.style.height = `${resultContainer.offsetHeight}px`
        }

        if (paginationClicked) {
            window.scrollTo({ top: scrollDiv, behavior: 'smooth'})
        }

        const response = await fetch('/ajax/filter-content-type', {
            method: 'POST',
            body: JSON.stringify({
                search: searchWord.value,
                content_type: [contentType.value],
                industries: selectedIndustries.value,
                services: selectedServices.value,
                page: currentPage.value,
                records_per_page: itemsPerPage
            })
        })
        
        const data = await response.json()
        totalItems.value = data.pagination.total_items
        loadedPages.value.push(page)

        if (contentType.value === 'blog') {
            for await (const item of data.items) {
                item.authorName = await getAuthors(item.fieldValues.authors)
            }
        }

        if (jumpedPages.value.includes(page)) {
            jumpedPages.value = jumpedPages.value.filter((item) => item !== page)
            contentTypeItems.value.splice((currentPage.value - 1) * itemsPerPage, itemsPerPage, ...data.items)
        } else {
            contentTypeItems.value = [...contentTypeItems.value, ...data.items]
        }

        if (resultContainer) {
            resultContainer.style.height = 'auto'
        }

        isLoading.value = false

    } catch (error) {
        isThereAnError.value = true
        console.log(error)
    }
}

const clearFilters = () => {
    mobileFiltersStatus.value = ''
    searchWord.value = null
    selectedIndustries.value = []
    selectedServices.value = []
    loadedPages.value = []
    contentTypeItems.value = []
    currentPage.value = 1
    fetchCallActions()
}

const getAuthors = async (authors) => {
    if (
        authors.length === 0 ||
        (authors.length === 1 && authors[0] === null)
    ) {
        return 'Luxoft'
    }

    let authorsNames = []
    for await (const author of authors) {
        const response = await fetch(`/api/contents?page=1&pageSize=1&id=${author}&contentType=people`)
        const data = await response.json()

        if (
            data.length > 0 &&
            data[0]?.fieldValues
        ) {
            authorsNames.push(data[0].fieldValues.name)
        }
    }

    return authorsNames.join(', ')
}

onMounted(() => {
    fetchData()
})
</script>
