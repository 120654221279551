<template>
    <div class="row">
        <div class="col-12 vue-filter-result-container">
            <div class="container event">
                <h2>Upcoming events</h2>
                <div v-if="upcomingItems.length < 1 && !isLoadingUpcoming" class="row">
                    <div class="col-12">
                        <div class="error-container">
                            <h5>No results were found.</h5>
                        </div>
                    </div>
                </div>
                <div v-if="isThereAnErrorUpcoming" class="row">
                    <div class="col-12">
                        <div class="error-container">
                            <img src="theme/luxoft/assets/images/icons/circle-exclamation-solid.svg" class="error-icon" alt="error icon">
                            <h5>Something went wrong, please try again.</h5>
                        </div>
                    </div>
                </div>
                <div v-if="isLoadingUpcoming && !isThereAnErrorUpcoming" class="row">
                    <span class="loading-spinner"></span>
                </div>
                <div class="row mt-6">
                    <template v-for="event in eventsUpcoming">
                        <SingleEventUpcoming
                            v-if="event?.id"
                            :event="event"
                            :key="event.id"
                        />
                    </template>
                </div>
            </div>

            <div class="container event">
                <h2>Past events</h2>
                <div v-if="pastItems.length < 1 && !isLoadingPast" class="row">
                    <div class="col-12">
                        <div class="error-container">
                            <h5>No results were found.</h5>
                        </div>
                    </div>
                </div>
                <div v-if="isThereAnErrorPast" class="row">
                    <div class="col-12">
                        <div class="error-container">
                            <img src="theme/luxoft/assets/images/icons/circle-exclamation-solid.svg" class="error-icon" alt="error icon">
                            <h5>Something went wrong, please try again.</h5>
                        </div>
                    </div>
                </div>
                <div class="row mt-4">
                    <template v-for="event in eventsPast">
                        <SingleEventPast
                            v-if="event?.id"
                            :event="event"
                            :key="event.id"
                        />
                    </template>
                </div>
                <div v-if="isLoadingPast && !isThereAnErrorPast" class="row">
                    <span class="loading-spinner"></span>
                </div>

                <div v-if="currentPage < totalPages" class="row">
                    <div class="col text-center">
                        <button type="button" @click.prevent="onClickHandler()" class="btn btn-outline-primary event__btn">Load more</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, computed } from 'vue'
import SingleEventUpcoming from '../components/SingleEventUpcoming.vue'
import SingleEventPast from '../components/SingleEventPast.vue'

const totalPages = ref(0)
const totalItems = ref(0)
const upcomingItems = ref([])
const currentPage = ref(1)
const itemsPerPage = 8
const pastItems = ref([])
const isLoadingUpcoming = ref(null)
const isLoadingPast = ref(null)
const isThereAnErrorUpcoming = ref(null)
const isThereAnErrorPast = ref(null)

const eventsUpcoming = computed( () => {
    const values = upcomingItems.value
    if (values[0]?.id !== undefined) return values
})

const eventsPast = computed( () => {
    const values = pastItems.value
    if (values[0]?.id !== undefined) return values
})

const onClickHandler = () => {
    currentPage.value = currentPage.value === totalPages.value ? 1 : currentPage.value += 1
    fetchData(currentPage.value)
}

const fetchData = (page = 1) => {
    currentPage.value = page
    getPastEvents()
    getUpcomingEvents()
}

const getUpcomingEvents = async () => {
    try {
        isThereAnErrorUpcoming.value = false
        isLoadingUpcoming.value = true
        const response = await fetch('/ajax/upcoming-event', {
            method: 'POST',
            body: JSON.stringify({
                content_type: ['events'],
                records_per_page: itemsPerPage
            })
        })

        upcomingItems.value = await response.json()
        isLoadingUpcoming.value = false
    } catch (error) {
        isThereAnErrorUpcoming.value = true
        console.log(error)
    }
}

const getPastEvents = async () => {
    try  {
        isThereAnErrorPast.value = false
        isLoadingPast.value = true

        const response = await fetch('/ajax/past-event', {
            method: 'POST',
            body: JSON.stringify({
                content_type: ['events'],
                records_per_page: itemsPerPage
            })
        })

        const data = await response.json()

        totalItems.value = data.pagination.total_items
        totalPages.value = data.pagination.total_pages
        pastItems.value = [...pastItems.value, ...data.items]
        isLoadingPast.value = false

    } catch (error) {
        isThereAnErrorPast.value = true
        console.log(error)
    }
}

fetchData()
</script>
