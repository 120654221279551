<template>
    <div class="col-12 search-results__results--item">
        <h5 class="title" v-html="item.fieldValues.title.en" />
        <p class="description" v-html="generateDescription(item.id)" />
        <a :href="generateAbsolutePageUrl(item.id)">
            {{ generateAbsolutePageUrl(item.id) }}
        </a>
    </div>

    
</template>

<script setup>
const props = defineProps({
    item: {
        type: Object,
        required: true,
        default: () => [],
    },
    urls: {
        type: Object,
        required: true,
        default: () => [],
    },
    excerpts: {
        type: Object,
        required: true,
        default: () => [],
    }
})

const generateDescription = (itemId) => {
    const found = props.excerpts.find(url => url.id === itemId)
    const removeAllBetweenBraces = found.excerpt.replace(/{.*}/, '')
    return removeAllBetweenBraces.replace(/%|{|}/g, '')
}

const generateAbsolutePageUrl = (itemId) => {
    const found = props.urls.find(url => url.id === itemId)
    return found.absolute_url
}
</script>
