<template>
    <div
        :class="`${type}-item-${index}`"
        class="single-insight"
    >
        <div class="single-insight--image">
            <img v-if="getImage(type, index, source)"
                :src="insight.fieldValues.photo.path"
                :alt="`${insight.fieldValues.photo.alt} | ${insight.fieldValues.title.en}`"
            >
        </div>
        <div class="single-insight--industry-category">
            <template v-if="atLeastOneTaxonomy">
                <p >{{ getFirstIndustry(insight.taxonomyValues) }}</p>
                <span class="mx-1">|</span>
            </template>
            <p>{{ getContenType(insight.contentType) }}</p>
        </div>
        <a :href="insight.extras.link" class="stretched-link">
            <h6 class="single-insight--title" v-html="insight.fieldValues.title.en" />
        </a>
        <div class="single-insight--description">
            <p>
                {{ insight.fieldValues.preview_description_text }}
            </p>
        </div>
        <div class="single-insight--details">
            <img src="/theme/luxoft/assets/images/icons/timer.svg" alt="timer icon" class="timer-icon"/>
            <p>{{ insight.fieldValues.read_watch_time === 0 ? '3' : insight.fieldValues.read_watch_time }} min</p>
            <span class="mx-1">|</span>
            <p>{{ applyFormat(insight.publishedAt) }}</p>
        </div>
    </div>
</template>


<script setup>
import { computed } from 'vue'
import formatDate from '../composables/formatDate'
const grayItemsInFirstLoad = [1, 2, 6, 7]
const v1grayItemsInMoreLoad = [3, 4]
const v2grayItemsInMoreLoad = [1, 3]

const props = defineProps({
    insight: {
        type: Object,
        required: true,
        default: () => [],
    },
    index: {
        type: Number,
        required: true
    },
    type: {
        type: String,
        required: true
    },
    source: {
        type: String,
        required: true
    },
})

const applyFormat = formatDate()

const atLeastOneTaxonomy = computed( () => {
    if (
        (typeof props.insight.taxonomyValues.industries_t !== 'undefined' &&
        Object.keys(props.insight.taxonomyValues.industries_t).length > 0)
        ||
        (typeof props.insight.taxonomyValues.services_t !== 'undefined' &&
        Object.keys(props.insight.taxonomyValues.services_t).length > 0)
    ) {
        return true
    }

    return false
})

const getContenType = (type) => {

    let contentTypeName = ''

    switch (type) {
        case 'whitepapers':
            contentTypeName = 'White paper'
            break
        case 'pr':
            contentTypeName = 'Press release'
            break
        case 'videos':
            contentTypeName = 'Video'
            break
        case 'blog':
            contentTypeName = 'Blog post'
            break
        case 'case-studies':
            contentTypeName = 'Case study'
            break
    }

    return contentTypeName
}

const getFirstIndustry = (taxonomies) => {

    if (
        typeof taxonomies.industries_t !== 'undefined' &&
        Object.keys(taxonomies.industries_t).length > 0
    ) {
        /**
         * DGTLDEV-425073 We avoid displaying "Banking and Capital Markets" for now.
         */
        let taxonomyKey = Object.keys(taxonomies.industries_t)[0]

        if (taxonomies.industries_t[taxonomyKey] === 'Banking and Capital Markets') {
            if (Object.keys(taxonomies.industries_t).length > 1) {
                taxonomyKey = Object.keys(taxonomies.industries_t)[1]
                return taxonomies.industries_t[taxonomyKey]
            } else {
                return getFirstService(taxonomies)
            }
        }

        return taxonomies.industries_t[taxonomyKey]
    }

    return getFirstService(taxonomies)
}

const getFirstService = (taxonomies) => {
    if (
        typeof taxonomies.services_t !== 'undefined' &&
        Object.keys(taxonomies.services_t).length > 0
    ) {
        const taxonomyKey = Object.keys(taxonomies.services_t)[0]
        return taxonomies.services_t[taxonomyKey]
    }

    return ''
}

const getImage = (type, index, source) => {
    /**
     * We return false if the current item is in the gray box to avoid loading its image
     */
    if (
        (type === 'first' && grayItemsInFirstLoad.includes(index))
    ) {
        return false
    }

    if (type === 'more') {
        if (
            (source === 'standard' && v1grayItemsInMoreLoad.includes(index)) ||
            (source === 'c-items-two-gray-one-image' && v2grayItemsInMoreLoad.includes(index)) ||
            (source === 'e-items-two-gray')
        ) {
            return false
        }
    }

    return true
}
</script>
