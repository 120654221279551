const howHelp = document.getElementsByClassName('how-we-help--hover');
for (let j = 0; j < howHelp.length; j++) {
  const accordion = howHelp[j].getElementsByClassName('col');
  if (accordion.length) {
    function clearClasses() {
      for (let i = 0; i < accordion.length; i++) {
        accordion[i].classList.remove('active')
      }
    }

    for (let i = 0; i < accordion.length; i++) {
      accordion[i].addEventListener('click', function (event) {
        if (accordion[i].className.includes('active')) {
          clearClasses();
        } else {
          clearClasses();
          accordion[i].classList.add('active');
        }
      })
    }
  }
}

$(document).ready(function() {
  const fixHeight = (wv) => {
    const target = '.how-we-help--purple-icon .block-header h3';
    $(target).removeAttr("style")
    let maxHeight = 0;
    const heights = [0, 0]

    if (wv >= 810 && wv <= 1235) {
      let firstRowHeight = 0, secondRowHeight = 0
      const elements = $(target)

      elements.each(function (index) {
        if (index <= parseInt(elements.length) / 2 - 1) {
          heights[0] = Math.max(heights[0], $(this).height());
        } else {
          heights[1] = Math.max(heights[1], $(this).height());
        }
      });

      elements.each(function (index) {
        if (index <= parseInt(elements.length) / 2 - 1) {
          $(this).height(heights[0]);
        } else {
          $(this).height(heights[1]);
        }
      });
    } else if (wv > 1235) {
      $(target).each(function (index) {
        heights[index] = $(this).height();
        maxHeight = Math.max(maxHeight, $(this).height());
      });
      $(target).height(maxHeight);
    }
  }


  let timer;
  $(window).resize(function() {
    clearTimeout(timer);

    timer = setTimeout(function() {
      fixHeight();
    }, 300);
  });
  fixHeight($(window).width())
});

$(document).ready(function() {
  const tabletsHeight = (wv) => {
    const howWeHelpRow = $('.container-how-we-help .row')
    const keyPointsRow = $('.container-key-points .row')

    if (wv >= 599 && wv <= 904) {
      if (howWeHelpRow) {
        howWeHelpRow.each(function () {
          const row = $(this)
          lastColWidth(row)
        })
      }
      if (keyPointsRow) {
        keyPointsRow.each(function () {
          const row = $(this)
          lastColWidth(row)
        })
      }
    }
  }

  function lastColWidth(row) {
    if (row[0].childElementCount % 2 === 0) {
      return
    } else {
      row[0].lastElementChild.style.width = '100%'
    }
  }

  let timer;
  $(window).resize(function() {
    clearTimeout(timer);

    timer = setTimeout(function() {
      tabletsHeight();
    }, 300);
  });
  tabletsHeight($(window).width())
})
