<template>
    <section class="search-results">
        <div class="search-results__header">
            <div class="container">
                <div class="row">
                    <div class="col">
                        <h3 v-if="allResultItems.length < 1 && !isLoading && !isThereAnError">
                            No results were found for "{{ searchedWord }}"
                        </h3>
                        <h3 v-if="allResultItems.length > 0 && !isThereAnError">
                            Results for "{{ searchedWord }}"
                        </h3>
                        <h3 v-if="isThereAnError">
                            Something went wrong, please try again.
                        </h3>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <div class="search-results__results vue-filter-result-container">
        <div class="container">
            <div v-if="isLoading && !isThereAnError" class="row">
                <span class="loading-spinner"></span>
            </div>
            <div v-if="allResultItems.length > 0 && !isThereAnError" class="col-12 search-results__results--total">
                <p><b>Showing {{ recordsFrom }} - {{ recordsTo }} of {{ totalItems }} results</b></p>
            </div>
            <div class="col-12 search-results__results--item">
                <SingleSearch
                    v-for="item in filteredItems"
                    :item="item"
                    :urls="absoluteUrls"
                    :excerpts="recordsExcerpts"
                    :key="item.id"
                />
            </div>
        </div>
    </div>
    <div class="search-results__pagination">
        <div class="container">
            <div class="row">
                <div class="col">
                    <vue-awesome-paginate
                        :total-items="totalItems"
                        :items-per-page="itemsPerPage"
                        :max-pages-shown="4"
                        v-model="currentPage"
                        :on-click="onClickHandler"
                        :show-breakpoint-buttons="true"
                        class="pagination"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, reactive, computed } from 'vue'
import SingleSearch from '../components/SingleSearch.vue'

const totalItems = ref(0)
const currentPage = ref(1)
const itemsPerPage = 10
const loadedPages = ref([])
const isLoading = ref(null)
const isThereAnError = ref(null)
const searchedWord = ref(null)
const jumpedPages = ref([])
const allResultItems = ref([])
const recordsFrom = ref(null)
const recordsTo = ref(null)
const absoluteUrls = ref([])
const recordsExcerpts = ref([])
const scrollDiv = document.getElementById('app').offsetTop
const urlParams = new URLSearchParams(window.location.search)
const searchPage = ref(1)

const filteredItems = computed( () => {
    const values = allResultItems.value.slice((currentPage.value - 1) * itemsPerPage, currentPage.value * itemsPerPage)
    if (values[0]?.id !== undefined) return values
})

const onClickHandler = (page) => {
    const paginationClicked = true
    fetchData(page, paginationClicked)
}

const fetchData = async (page = 1, paginationClicked = false) => {
    if (!loadedPages.value.includes(page)) {
        const lastLoadedPage = loadedPages.value[loadedPages.value.length - 1]
        /**
         * If the users click on a page bigger than the next one
         * From page 1 to 3 for instance
         */
        if (page > (lastLoadedPage + 1) && page !== 1) {
            const items = []
            let jumpPage = lastLoadedPage + 1

            while (jumpPage < page)  {
                if (!loadedPages.value.includes(jumpPage)) {
                    jumpedPages.value.push(jumpPage)
                    allResultItems.value.push({}, {}, {}, {}, {}, {}, {}, {}, {}, {})
                }
                jumpPage++
            }
        }

        searchedWord.value = urlParams.get('q')
        searchPage.value = page
        fetchDataCall(page, paginationClicked)
    }
}

const fetchDataCall = async (page = 1, paginationClicked = false) => {
    try  {
        isThereAnError.value = false
        isLoading.value = true
        const resultContainer = document.querySelector('.search-results__results')

        if (resultContainer) {
            resultContainer.style.height = `${resultContainer.offsetHeight}px`
        }

        if (paginationClicked) {
            window.scrollTo({ top: scrollDiv, behavior: 'smooth'})
        }

        const response = await fetch('/ajax/search', {
            method: 'POST',
            body: JSON.stringify({
                search: searchedWord.value,
                page: searchPage.value
            }),
        })

        const data = await response.json()

        totalItems.value = data.records.pagination.total_items
        loadedPages.value.push(page)

        if (jumpedPages.value.includes(page)) {
            jumpedPages.value = jumpedPages.value.filter((item) => item !== page)
            allResultItems.value.splice((currentPage.value - 1) * itemsPerPage, itemsPerPage, ...data.records.items)
        } else {
            allResultItems.value = [...allResultItems.value, ...data.records.items]
        }

        absoluteUrls.value = [...absoluteUrls.value, ...data.urls]
        recordsExcerpts.value = [...recordsExcerpts.value, ...data.excerpts]
        recordsFrom.value = (currentPage.value * itemsPerPage) - (itemsPerPage - 1)
        recordsTo.value = recordsFrom.value  + (filteredItems.value.length - 1)

        if (resultContainer) {
            resultContainer.style.height = 'auto'
        }

        isLoading.value = false

    } catch (error) {
        isThereAnError.value = true
        console.log(error)
    }
}

fetchData()
</script>
