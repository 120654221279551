<template>
    <div class="container">
        <div class="row">
            <div class="col-12 col-lg-2 filter-container">
                <div class="embedded-filter-search">
                    <div class="embedded-filter-search__search-icon">
                        <button type="submit">
                            <img src="/theme/luxoft/assets/images/icons/search.svg" alt="search icon"/>
                        </button>
                    </div>
                    <form @submit.prevent="applyFilters()" class="embedded-filter-search__form">
                        <input type="text" name="search" v-model.trim="searchWord" class="form-control embedded-filter-search__input" placeholder="Search">
                    </form>
                    <div v-show="searchWord" class="embedded-filter-search__cancel-icon">
                        <button type="button" @click="searchWord = null">
                            <img src="/theme/luxoft/assets/images/custom-pages/global-presence/close-icon.png" alt="close icon"/>
                        </button>
                    </div>
                    <div class="switch">
                        <button type="button" @click="mobileFiltersStatus = 'active'">
                            <img src="/theme/luxoft/assets/images/icons/filter.svg" alt="filter icon"/>
                            Filters
                        </button>
                    </div>
                </div>
                <div class="filters-col" :class="mobileFiltersStatus">
                    <div class="filters-col__cancel-icon">
                        <button type="button" @click="mobileFiltersStatus = ''">
                            <img src="/theme/luxoft/assets/images/custom-pages/global-presence/close-icon.png" alt="close icon"/>
                        </button>
                    </div>
                    <div class="filters-col__row insights-filters">
                        <p class="filters-col__row__title">Content category</p>
                        <div class="filters-col__row__list insights-filters">
                            <a
                                v-for="(item, key) in categoriesList"
                                :key="key"
                                class="second-level-link semibold"
                                :href="'financial-services/' + key"
                            >
                                {{ item }}
                                <i class="arrow"></i>
                            </a>
                        </div>
                    </div>
                    <div v-if="filtersUsed" class="filters-col__row insights-filters">
                        <button class="clear-insights-filters" @click.prevent="clearFilters()">
                            Clear all <img src="/theme/luxoft/assets/images/custom-pages/global-presence/close-icon.png" alt="close icon"/>
                        </button>
                    </div>
                </div>
            </div>
            <div class="col-12 col-lg-10 vue-filter-result-container">
                <div v-if="recommendedPost.length > 1 && !filtersUsed" class="insights-page__first-results">
                    <div class="row f-width section-title">
                        <div class="col-12">
                            <p>Recommended insights</p>
                        </div>
                    </div>
                    <template v-for="(insight, index) in recommendedPost">
                        <SingleInsightHub
                            v-if="insight !== undefined && insight?.fieldValues !== undefined"
                            :insight="insight"
                            :index="index"
                            :key="insight.id"
                            :type="'first'"
                            :source="'standard'"
                        />
                    </template>
                </div>

                <div v-if="firstLoadItems.length < 1 && !isLoading && filtersUsed" class="d-inline-block w-100">
                    <div class="error-container">
                        <p><b>No results were found.</b></p>
                    </div>
                </div>

                <div v-if="firstLoadItems.length > 0" class="insights-page__first-results">
                    <div class="row f-width section-title d-inline-block" :class="{ 'mt-4': !filtersUsed }">
                        <div class="col-12">
                            <p>Latest insights</p>
                        </div>
                    </div>
                    <template v-for="(insight, index) in firstLoadItems">
                        <SingleInsightHub
                            v-if="insight !== undefined && insight?.fieldValues !== undefined"
                            :insight="insight"
                            :index="parseInt(index + 3)"
                            :key="insight.id"
                            :type="'first'"
                            :source="'standard'"
                        />
                    </template>
                </div>

                <div v-if="moreItemsLoaded.length > 0" class="insights-second-level__results">
                    <template v-for="(item) in moreItemsLoaded">
                        <template v-for="(element, key) in item">
                            <div
                                v-if="key.includes('items')"
                                class="insights-second-level__results--container"
                                :class="key"
                                :key="key"
                            >
                                <template v-for="(insight, index) in element">
                                    <SingleInsightHub
                                        v-if="insight !== undefined && insight?.fieldValues !== undefined"
                                        :insight="insight"
                                        :index="parseInt(index)"
                                        :type="'more'"
                                        :source="key"
                                        :key="index"
                                    />
                                </template>
                            </div>

                            <div
                                v-if="key.includes('people')"
                                class="insights-second-level__results--container people"
                                :key="key"
                            >
                                <template v-for="(person, index) in element">
                                    <SinglePersonInsight
                                        v-if="person !== undefined"
                                        :person="person"
                                        :key="index"
                                    />
                                </template>
                            </div>

                            <template v-if="key.includes('cta')">
                                <template v-for="(cta, index) in element">
                                    <CtaBanner
                                        v-if="cta === '1'"
                                        :key="index"
                                    >
                                        <template v-slot:subheading>
                                            Beat the fintech and neobank competition with Luxoft's financial services technology solutions.
                                        </template>
                                        <template v-slot:button>
                                            <a class="btn btn-text btn-icon btn-white" href="/industries/banking">
                                                <div class="text-container">
                                                    <div class="text">
                                                        Learn more
                                                    </div>
                                                </div>
                                            </a>
                                        </template>
                                    </CtaBanner>
                                </template>
                            </template>
                        </template>
                    </template>
                </div>

                <div v-if="isThereAnError" class="row f-width">
                    <div class="col-12">
                        <div class="error-container">
                            <img src="/theme/luxoft/assets/images/icons/circle-exclamation-solid.svg" class="error-icon" alt="error icon">
                            <h5>Something went wrong, please try again.</h5>
                        </div>
                    </div>
                </div>

                <div v-if="isLoading && !isThereAnError" class="row f-width">
                    <span class="loading-spinner"></span>
                </div>

                <div v-if="!isLoading && !isThereAnError && (callSettings.page < totalPages || savedItems.length > 0)" class="row pt-4 f-width">
                    <div class="col text-center">
                        <button type="button" @click.prevent="onClickHandler()" class="btn btn-outline-primary event__btn">LOAD MORE</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, reactive, onMounted } from 'vue'
import usefetchCall from '../composables/insightsFetch'
import SingleInsightHub from '../components/SingleInsightHub.vue'
import SinglePersonInsight from '../components/SinglePersonInsight.vue'
import CtaBanner from '../components/CtaBanner.vue'

const props = defineProps({
    recommendedPost: {
        type: Array,
        required: true,
        default: () => [],
    },
    categoriesList: {
        type: Object,
        required: true,
        default: () => [],
    }
})

const { isThereAnError,
        isLoading,
        totalPages,
        firstLoadItems,
        moreItemsLoaded,
        fetchCall,
        loadMoreCounterClicked,
        savedItems,
        getRestOfSavedItems
    } = usefetchCall()

const searchWord = ref(null)
const allContentTypes = ['pr', 'videos', 'blog', 'case-studies', 'whitepapers']
const mobileFiltersStatus = ref('')
const filtersUsed = ref(false)
const callSettings = reactive({
                        "search": null,
                        "content_type": allContentTypes,
                        "industries": ['banking', 'banking-option', 'capital-markets', 'insurance'],
                        "services": [],
                        "categories": [],
                        "page": 1,
                        "records_per_page": 8,
                    })

const onClickHandler = () => {
    callSettings.page++
    loadMoreCounterClicked.value++

    if (callSettings.page > totalPages.value) {
        getRestOfSavedItems()
    } else {
        fetchData(false)
    }
}

const fetchData = async (firstLoad = true) => {
    await fetchCall({ firstLoad, callSettings, extractItemsFromRes: true })
}

const applyFilters = () => {
    if (searchWord.value) {
        filtersUsed.value = true
        mobileFiltersStatus.value = ''
        callSettings.page = 1
        callSettings.search = searchWord.value
        firstLoadItems.value = []
        moreItemsLoaded.value = []
        fetchData()
    }
}

const clearFilters = () => {
    filtersUsed.value = false
    mobileFiltersStatus.value = ''
    searchWord.value = null
    firstLoadItems.value = []
    moreItemsLoaded.value = []
    callSettings.page = 1
    callSettings.search = null
    fetchData()
}

onMounted(() => {
    fetchData()
})
</script>
