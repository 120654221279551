<template>
    <div class="container">
        <div class="row">
            <div class="col-12 col-lg-2 filter-container">
                <div class="embedded-filter-search">
                    <div class="embedded-filter-search__search-icon">
                        <button type="submit">
                            <img src="/theme/luxoft/assets/images/icons/search.svg" alt="search icon"/>
                        </button>
                    </div>
                    <form @submit.prevent="applyFilters()" class="embedded-filter-search__form">
                        <input type="text" name="search" v-model.trim="searchWord" class="form-control embedded-filter-search__input" placeholder="Search">
                    </form>
                    <div v-show="searchWord" class="embedded-filter-search__cancel-icon">
                        <button type="button" @click="searchWord = null">
                            <img src="/theme/luxoft/assets/images/custom-pages/global-presence/close-icon.png" alt="close icon"/>
                        </button>
                    </div>
                    <div class="switch" data-cy="switch-filters-mobile">
                        <button type="button" @click="mobileFiltersStatus = 'active'">
                            <img src="/theme/luxoft/assets/images/icons/filter.svg" alt="filter icon"/>
                            Filters
                        </button>
                    </div>
                </div>
                <div class="filters-col" :class="mobileFiltersStatus" data-cy="filters-container">
                    <div class="filters-col__cancel-icon">
                        <button type="button" @click="mobileFiltersStatus = ''">
                            <img src="/theme/luxoft/assets/images/custom-pages/global-presence/close-icon.png" alt="close icon"/>
                        </button>
                    </div>
                    <div class="filters-col__row insights-filters">
                        <p class="filters-col__row__title">Industries</p>
                        <div class="filters-col__row__list insights-filters industries-filters">
                            <button
                                v-for="(item, key) in industriesList"
                                :key="key"
                                class="sigle-filter-btn fw-normal"
                                :class="getButtonClass('industry', key)"
                                @click.prevent="applyFilters('industry', key)"
                                type="button"
                            >
                                {{ item }}
                            </button>
                        </div>
                    </div>
                    <div class="filters-col__row insights-filters">
                        <p class="filters-col__row__title">Content types</p>
                        <div class="filters-col__row__list insights-filters contenttypes-filters">
                            <button
                                class="sigle-filter-btn fw-normal"
                                @click.prevent="applyFilters('content-type', 'blog')"
                                :class="getButtonClass('content-type', 'blog')"
                                type="button"
                            >
                                Blog post
                            </button>
                            <button
                                class="sigle-filter-btn fw-normal"
                                @click.prevent="applyFilters('content-type', 'case-studies')"
                                :class="getButtonClass('content-type', 'case-studies')"
                                type="button"
                            >
                                Case study
                            </button>
                            <button
                                class="sigle-filter-btn fw-normal"
                                @click.prevent="applyFilters('content-type', 'videos')"
                                :class="getButtonClass('content-type', 'video')"
                                type="button"
                            >
                                Video
                            </button>
                            <button
                                class="sigle-filter-btn fw-normal"
                                @click.prevent="applyFilters('content-type', 'whitepapers')"
                                :class="getButtonClass('content-type', 'whitepapers')"
                                type="button"
                            >
                                White paper
                            </button>
                        </div>
                    </div>
                    <div v-if="selectedIndustries.length > 0 || selectedContentType.length > 0" class="filters-col__row insights-filters">
                        <button class="clear-insights-filters" data-cy="clear-insights-filters" @click.prevent="clearFilters()">
                            Clear all <img src="/theme/luxoft/assets/images/custom-pages/global-presence/close-icon.png" alt="close icon"/>
                        </button>
                    </div>
                </div>
            </div>
            <div class="col-12 col-lg-10 vue-filter-result-container">
                <div v-if="firstLoadItems.length < 1 && !isLoading" class="d-inline-block mt-6 w-100">
                    <div class="error-container">
                        <p><b>No results were found.</b></p>
                    </div>
                </div>
                <div v-if="firstLoadItems.length > 0" class="insights-page__first-results">
                    <template v-for="(insight, index) in firstLoadItems">
                        <SingleInsightHub
                            v-if="insight !== undefined && insight?.fieldValues !== undefined"
                            :insight="insight"
                            :index="index"
                            :key="insight.id"
                            :type="'first'"
                            :source="'standard'"
                        />
                    </template>
                </div>
                <div v-if="moreItemsLoaded.length > 0" class="insights-page__more-results">
                    <template v-for="(item) in moreItemsLoaded">
                        <template v-for="(insight, index) in item">
                            <SingleInsightHub
                                v-if="insight !== undefined && insight?.fieldValues !== undefined"
                                :insight="insight"
                                :index="index"
                                :key="insight.id"
                                :type="'more'"
                                :source="'standard'"
                            />
                        </template>
                    </template>
                </div>
                <div v-if="isLoading && !isThereAnError" class="row f-width">
                    <span class="loading-spinner"></span>
                </div>
                <div v-if="isThereAnError" class="row f-width">
                    <div class="col-12">
                        <div class="error-container">
                            <img src="/theme/luxoft/assets/images/icons/circle-exclamation-solid.svg" class="error-icon" alt="error icon">
                            <h5>Something went wrong, please try again.</h5>
                        </div>
                    </div>
                </div>
                <div v-if="!isLoading && !isThereAnError && callSettings.page < totalPages" class="row pt-4 f-width">
                    <div class="col text-center">
                        <button type="button" @click.prevent="onClickHandler()" class="btn btn-outline-primary event__btn">LOAD MORE</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, reactive, onMounted } from 'vue'
import usefetchCall from '../composables/insightsFetch'
import SingleInsightHub from '../components/SingleInsightHub.vue'

const props = defineProps({
    industriesList: {
        type: Object,
        required: true,
        default: () => [],
    }
})

const { isThereAnError,
        isLoading,
        totalPages,
        firstLoadItems,
        moreItemsLoaded,
        fetchCall,
    } = usefetchCall()

const searchWord = ref(null)
const allContentTypes = ['pr', 'videos', 'blog', 'case-studies', 'whitepapers']
const selectedIndustries = ref([])
const selectedContentType = ref([])
const mobileFiltersStatus = ref('')
const defaultIndustries = [
                            'banking',
                            'insurance',
                            'automotive',
                            'healthcare',
                            'telecom',
                            'consumer-goods',
                            'energy-utilities',
                            'retail-ecommerce',
                            'travel-hospitality',
                            'transportation-logistics'
                        ]

const callSettings = reactive({
                        "search": null,
                        "content_type": allContentTypes,
                        "industries": defaultIndustries,
                        "services": [],
                        "categories": [],
                        "page": 1,
                        "records_per_page": 8
                    })

const applyFilters = (type = null, value = null) => {
    if (type === 'industry') {
        if (selectedIndustries.value.includes(value)) {
            const indexIndustry = selectedIndustries.value.indexOf(value)
            if (indexIndustry !== -1) {
                selectedIndustries.value.splice(indexIndustry, 1)
            }
        } else {
            selectedIndustries.value.push(value)
        }
    }

    if (type === 'content-type') {
        if (selectedContentType.value.includes(value)) {
            const contentIndex = selectedContentType.value.indexOf(value)
            if (contentIndex !== -1) {
                selectedContentType.value.splice(contentIndex, 1)
            }
        } else {
            selectedContentType.value.push(value)
        }
    }

    mobileFiltersStatus.value = ''
    callSettings.page = 1
    callSettings.search = searchWord.value
    callSettings.industries = (selectedIndustries.value.length > 0) ? selectedIndustries.value : defaultIndustries
    callSettings.content_type = (selectedContentType.value.length > 0) ? selectedContentType.value : allContentTypes
    firstLoadItems.value = []
    moreItemsLoaded.value = []
    fetchData()
}

const onClickHandler = () => {
    callSettings.page++
    fetchData(false)
}

const fetchData = async (firstLoad = true) => {
    await fetchCall({ firstLoad, callSettings })
}

const clearFilters = () => {
    mobileFiltersStatus.value = ''
    searchWord.value = null
    selectedIndustries.value = []
    selectedContentType.value = []
    firstLoadItems.value = []
    moreItemsLoaded.value = []
    callSettings.page = 1
    callSettings.search = null
    callSettings.industries = defaultIndustries
    callSettings.content_type = allContentTypes
    fetchData()
}

const getButtonClass = (type, value) => {
    if (
        type === 'industry' &&
        selectedIndustries.value.includes(value)
    ) {
        return 'active'
    }

    if (
        type === 'content-type' &&
        selectedContentType.value.includes(value)
    ) {
        return 'active'
    }

    return ''
}

onMounted(() => {
    fetchData()
})
</script>
