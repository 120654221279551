<template>
    <div class="insights-second-level__results--container cta-section cta-section--image-btn radius--base banner">
        <figure>
            <img src="https://www.luxoft.com/files/corporate/banner-CTA.png" alt="background image">
        </figure>
        <div class="cta-section--image-btn__wrapper">
            <div class="cta-section--image-btn__content">
                <div class="text-center">
                    <h2 class="banner-text">
                        <slot name="subheading"></slot>
                    </h2>
                    <div>
                        <slot name="button"></slot>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>