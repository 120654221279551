window.__domLoadDone = false

document.addEventListener('DOMContentLoaded', function () {
  window.__domLoadDone = true
  require('./scripts/aos-animation')
  require('./scripts/canonical')
  require('./scripts/any-meet-us-form')
  require('./scripts/global-search')
  require('./scripts/partners')
  require('./scripts/go-to-section')
  require('./scripts/img-markup')
  require('./scripts/back-to-top')
  require('./components/hero-carousel')
  require('./components/why-luxoft')
  require('./components/why-luxoft-two-columns')
  require('./components/main')
  require('./components/hero')
  require('./components/marketo')
  require('./components/how-we-help')
  require('./components/benefits')
  require('./components/menu')
  require('./components/case-studies')
  require('./components/awards')
  require('./components/team')
  require('./components/gray-column')
  require('./components/social-buttons')
  require('./components/how-we-help-fix')
  require('./components/consent-form')
  require('./components/meet-us-form')
  require('./components/contact-form')
  require('./components/global-presence')
  require('./components/collapsible-read-more')
  require('./components/services-vertical-tabs')
  require('./scripts/loading-lazy-images')
}, false)
