import { ref } from 'vue'

const isThereAnError = ref(null)
const isLoading = ref(null)
const totalPages = ref(0)
const firstLoadItems = ref([])
const savedItems = ref([])
const moreItemsLoaded = ref([])
const loadMoreCounterClicked = ref(0)

const fetchCall = async ({ firstLoad, callSettings, extractItemsFromRes = false }) => {
  isThereAnError.value = false
  isLoading.value = true

  try  {
    const response = await fetch('/ajax/content-hub', {
      method: 'POST',
      body: JSON.stringify({
          search: callSettings.search,
          content_type: callSettings.content_type,
          industries: callSettings.industries,
          services: callSettings.services,
          categories: callSettings.categories,
          page: callSettings.page,
          records_per_page: callSettings.records_per_page
      })
    })

    if (!response) {
        throw new Error('Could not load the elements')
    }

    const data = await response.json()

    if (firstLoad) {
      if (extractItemsFromRes) {
        savedItems.value = [...savedItems.value, ...data.items.slice(5, 8)]
        firstLoadItems.value = [...firstLoadItems.value, ...data.items.slice(0, 5)]
      } else {
        firstLoadItems.value = [...firstLoadItems.value, ...data.items]
      }
    } else {
      if (!extractItemsFromRes) {
          moreItemsLoaded.value.push(data.items)
      } else {
        const newItems = {
          'a-items-all-with-image': {
            1: savedItems.value[0],
            2: savedItems.value[1],
            3: savedItems.value[2],
          },
          'c-items-two-gray-one-image': {
            1: data.items[0],
            2: data.items[1],
            3: data.items[2],
          },
          'd-cta': {
            1: `${loadMoreCounterClicked.value}`,
          },
          'e-items-two-gray': {
            1: data.items[3],
            2: data.items[4],
          },
        }

        savedItems.value = []
        savedItems.value = [...savedItems.value, ...data.items.slice(5, 8)]
        moreItemsLoaded.value.push(newItems)
      }
    }

    totalPages.value = data.pagination.total_pages
    isLoading.value = false

  } catch (error) {
    isThereAnError.value = true
    console.log(error)
  }
}

const getRestOfSavedItems = () => {
  const newItems = {
    'a-items-all-with-image': {
      1: savedItems.value[0],
      2: savedItems.value[1],
      3: savedItems.value[2],
    }
  }

  savedItems.value = []
  moreItemsLoaded.value.push(newItems)
}

const usefetchCall = () => {
    return { isThereAnError,
            isLoading,
            totalPages,
            firstLoadItems,
            moreItemsLoaded,
            fetchCall,
            loadMoreCounterClicked,
            savedItems,
            getRestOfSavedItems
        }
}

export default usefetchCall
