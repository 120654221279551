(function () {
    //root links
    const links = document.querySelectorAll('.lux-menu ul li.has-children > a.root-link'),
        //submenu links
        submenuLinks = document.querySelectorAll('.lux-menu__submenuLvlTwo ul li.has-children > a.sub-root-link'),
        //mobile back btns
        backMobileToLvlOne = document.querySelectorAll('.back-mobileLvlOne'),
        backMobileToLvlTwo = document.querySelectorAll('.back-mobileLvlTwo'),
        //level two
        submenuLvlTwo = document.querySelectorAll('.lux-menu__submenuLvlTwo'),
        //level three
        submenuLvlThree = document.querySelectorAll('.lux-menu__submenuLvlThree'),
        //items with submenu lvl2
        itemsHasSubmenuLvlThree = document.querySelectorAll('.lux-menu ul li.has-children'),
        //items with submenu lvl3
        itemsHasSubmenuLvlTwo = document.querySelectorAll('.lux-menu__submenuLvlTwo ul li.has-children'),
        //close menu
        luxMenu = document.querySelector('.lux-menu'),
        menuSwitcher = document.querySelector('#menu-switcher')

    const menu = {
        init: function () {
            this.openLvlTwo()
            this.openLvlThree()
            this.mobileBackBtns()
            this.closeOutside()
            this.closeBtn()
        },
        addClickEvents: function (arr, removeActive, setWidthClass) {
            const that = this
            arr.map((element) => {
                element.addEventListener("click", function (event) {
                    event.stopPropagation()

                    removeActive.map((item) => {
                        that.removeActives(item)
                    })

                    this.nextElementSibling.classList.add('active')
                    this.parentNode.classList.add('active')
                    that.setWidth(setWidthClass)
                }, true)
            })

        },
        openLvlTwo: function () {
            const removeActive = [submenuLvlTwo, submenuLvlThree, itemsHasSubmenuLvlThree],
                setWidthClass = 'lux-menu--levelOne'
            this.addClickEvents([...links], removeActive, setWidthClass)
        },

        openLvlThree: function () {
            const removeActive = [submenuLvlThree, itemsHasSubmenuLvlTwo],
                setWidthClass = 'lux-menu--levelTwo'
            this.addClickEvents([...submenuLinks], removeActive, setWidthClass)
        },
        mobileBackBtns: function () {
            const removeActiveOne = [submenuLvlTwo, submenuLvlThree, itemsHasSubmenuLvlThree],
                removeActiveTwo = [submenuLvlThree, itemsHasSubmenuLvlTwo],
                setWidthClassOne = 'lux-menu--levelOne',
                setWidthClassTwo = 'lux-menu--levelTwo'
            this.addClickEvents([...backMobileToLvlOne], removeActiveOne, setWidthClassOne)
            this.addClickEvents([...backMobileToLvlTwo], removeActiveTwo, setWidthClassTwo)
        },
        closeOutside: function () {
            const that = this
            document.addEventListener('click', function (event) {
                const header = document.querySelector('header'),
                    isClickInsideOne = luxMenu.contains(event.target),
                    isClickInsideTwo = menuSwitcher.contains(event.target)

                if (isClickInsideOne || isClickInsideTwo) {
                    if (event.target.nodeName === "A") {
                        location.href = event.target.href
                    }
                }
                else {
                    if (header.classList.contains('active')) {
                        header.classList.remove('active')
                        that.setWidth('')
                        that.removeActives(submenuLvlTwo)
                        that.removeActives(submenuLvlThree)
                        that.removeActives(itemsHasSubmenuLvlThree)
                    }
                }
            }, false)
        },
        closeBtn: function () {
            const that = this
            menuSwitcher.addEventListener('click', function (event) {
                const header = document.querySelector('header')
                if (header.classList.contains('active')) {
                    header.classList.remove('active')
                    that.setWidth('')
                    that.removeActives(submenuLvlTwo)
                    that.removeActives(submenuLvlThree)
                    that.removeActives(itemsHasSubmenuLvlThree)
                } else {
                    header.classList.add('active')
                }
            }, true)
        },
        removeActives: function (submenu) {
            [...submenu].map((item) => {
                item.classList.remove('active')
            })
        },
        setWidth: function (levelClass) {
            luxMenu.classList.remove("lux-menu--levelOne", "lux-menu--levelTwo")
            levelClass !== '' && luxMenu.classList.add(levelClass)
        }
    }
    menu.init()
}())

const nav = document.querySelector('.header'),
        topOfNav = nav.offsetTop

const relatedContentRightColumn = document.querySelector('.blog-post-right-column__right-column .related-content')
const authorsContainer = document.querySelector('.blog-post-right-column .blog-post-right-column__authors')
const relatedContentBottom = document.querySelector('.blog-post-right-column__related-content')
const scrollspyList = document.querySelector('.scrollspy-list')
const relatedContentBottomCS = document.querySelector('.container-insights') ?? document.querySelector('.related-content-snippet')
const footer = document.querySelector('.footer')
const keyHighlights = document.querySelector('.container-key-highlights')
const heroBanners =
  [
    document.querySelector('.container-hero-short-purple'),
    document.querySelector('.container-hero-short-black'),
    document.querySelector('.container-hero-purple-any-height'),
    document.querySelector('.container-hero-img'),
    document.querySelector('.container-hero-video')
  ]


// Sticky nav
function fixNav() {
    if (nav.classList.contains('header-is-fixed')) {
        return
    }

    if (window.pageYOffset > topOfNav) {
        nav.classList.add('fixed')
    } else {
        nav.classList.remove('fixed')
    }

    if (
        window.pageYOffset > 50 &&
        (window.location.pathname.includes('/blog/') || window.location.pathname.includes('/case-studies/'))

    ) {
        if (document.querySelector('.blog-post-right-column__banner')) {
            document.querySelector('.blog-post-right-column__banner').classList.add('fixed')
            document.querySelector('.blog-post-right-column__content .text-content').classList.add('scrolled')
            document.querySelector('.blog-post-right-column__right-column').classList.add('scrolled')
        }

        if (scrollspyList) {
          document.querySelector('.cs-content').classList.add('scrolled')
          document.querySelector('.cs-sidemenu').classList.add('scrolled')
        }
    } else {
        if (document.querySelector('.blog-post-right-column__banner')) {
            document.querySelector('.blog-post-right-column__banner').classList.remove('fixed')
            document.querySelector('.blog-post-right-column__content .text-content').classList.remove('scrolled')
            document.querySelector('.blog-post-right-column__right-column').classList.remove('scrolled')
        }

        if (scrollspyList) {
          document.querySelector('.cs-content').classList.remove('scrolled')
          document.querySelector('.cs-sidemenu').classList.remove('scrolled')
        }
    }

    if (scrollspyList) {
      if (heroBanners.includes(nav.nextElementSibling) && scrollspyList.getBoundingClientRect().top <= (keyHighlights ? nav.nextElementSibling.offsetHeight + keyHighlights.offsetHeight  : nav.nextElementSibling.offsetHeight) ) {
        scrollspyList.classList.add('fixed')
      } else if (!heroBanners.includes(nav.nextElementSibling) && scrollspyList.getBoundingClientRect().top <= 10 ) {
        scrollspyList.classList.add('fixed')
      }

      if (heroBanners.includes(nav.nextElementSibling) && (window.pageYOffset + 50) < (keyHighlights ? nav.nextElementSibling.offsetHeight + keyHighlights.offsetHeight  : nav.nextElementSibling.offsetHeight)) {
        scrollspyList.classList.remove('fixed')
        scrollspyList.classList.remove('scroll')
        scrollspyList.style.position = 'initial'
      } else if (!heroBanners.includes(nav.nextElementSibling) && window.pageYOffset <= 10) {
        scrollspyList.classList.remove('fixed')
        scrollspyList.classList.remove('scroll')
        scrollspyList.style.position = 'initial'
      }

      if (relatedContentBottomCS ? ((relatedContentBottomCS.getBoundingClientRect().top - scrollspyList.offsetHeight) <= 180) : ((footer.getBoundingClientRect().top - scrollspyList.offsetHeight) <= 650)) {
        if (!scrollspyList.classList.contains('scroll')) {
          scrollspyList.style.position = 'absolute'
          scrollspyList.style.top = (window.pageYOffset + 140) + 'px'
        }

        scrollspyList.classList.add('scroll')
        scrollspyList.classList.remove('fixed')
      }

      if (relatedContentBottomCS ? ((relatedContentBottomCS.getBoundingClientRect().top - scrollspyList.offsetHeight) > 180) : ((footer.getBoundingClientRect().top - scrollspyList.offsetHeight) > 100)) {
        if (scrollspyList.classList.contains('fixed')) {
          scrollspyList.style.position = 'fixed'
          scrollspyList.style.top = 150 + 'px'
        }

        scrollspyList.classList.add('fixed')
        scrollspyList.classList.remove('scroll')
      }
    }

    if (relatedContentRightColumn) {
        if (relatedContentRightColumn.getBoundingClientRect().top <= 148) {
            relatedContentRightColumn.classList.add('fixed')
        }

        if ((window.pageYOffset - authorsContainer.offsetHeight) < 100) {
            relatedContentRightColumn.classList.remove('fixed')
        }

        if ((relatedContentBottom.getBoundingClientRect().top - relatedContentRightColumn.offsetHeight) <= 200) {
            if (!relatedContentRightColumn.classList.contains('scroll')) {
                relatedContentRightColumn.style.marginTop = `${window.pageYOffset - authorsContainer.offsetHeight - 120}px`
            }

            relatedContentRightColumn.classList.add('scroll')
            relatedContentRightColumn.classList.remove('fixed')
        }

        if (
            relatedContentRightColumn.getBoundingClientRect().top >= 148 &&
            relatedContentRightColumn.classList.contains('scroll')
        ) {
            relatedContentRightColumn.style.marginTop = '0'
            relatedContentRightColumn.classList.add('fixed')
            relatedContentRightColumn.classList.remove('scroll')
        }
    }
}

window.addEventListener('scroll', fixNav)
fixNav()
