<template>
    <div class="col-12 col-lg-6">
        <div class="card mb-3">
            <img 
                :src="(caseStudy.fieldValues.photo.extension === '') ? 'https://dummyimage.com/615x526/000/fff' : caseStudy.fieldValues.photo.path"
                class="card-img"
                alt="case study image"
            >
            <div class="card-img-overlay text-white d-flex flex-column justify-content-end">
                <div class="mycasestudies__card-text" v-html="caseStudy.fieldValues.title.en" />
                <div>
                    <a class="btn btn-text btn-icon focus" :href="caseStudy.extras.link">
                        <div class="text-container">
                            <div class="text stretched-link">READ MORE</div>
                            <div class="arr-offering">
                                <i class="arr-small one"></i>
                                <i class="arr-small two"></i>
                                <i class="arr-small three"></i>
                            </div>
                        </div>
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
    const props = defineProps({
        caseStudy: {
            type: Object,
            required: true,
            default: () => [],
        }
    })
</script>

