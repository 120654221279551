if(document.querySelector('#contact_form_message_category')){
    document.querySelector('#contact_form_message_category').addEventListener('change', function() {
        const consent = this.options[this.selectedIndex].getAttribute('consent');
        if(consent === ""){
            document.querySelector("#CONTACT_CONSENT_OPTIONAL_B2B").style.display = 'none';
            document.querySelector("#CONTACT_CONSENT_OPTIONAL_B2E").style.display = 'none';
        }    
    
        if(consent === "B2B"){
            document.querySelector("#CONTACT_CONSENT_OPTIONAL_B2E").style.display = 'none';
            document.querySelector("#CONTACT_CONSENT_OPTIONAL_B2B").style.display = 'inline-block';
        }
    
        if(consent === "B2E"){
            document.querySelector("#CONTACT_CONSENT_OPTIONAL_B2E").style.display = 'inline-block';
            document.querySelector("#CONTACT_CONSENT_OPTIONAL_B2B").style.display = 'none';
        }
    })
}
