(function () {
    const whyLuxoftTwoColumns = {
        init: function () {
            this.getColumsData()
        },
        getColumsData: function () {
            const whyLuxoftSections = document.getElementsByClassName('why-luxoft-two-columns')
            for (const singleSection of whyLuxoftSections) {
                if (singleSection.getElementsByClassName('column').length > 1) {
                    const columns = singleSection.getElementsByClassName('column')
                    let columItemSizes = []
                    let itemSizes = []

                    for (const column of columns) {
                        const items = column.getElementsByClassName('item')
                        
                        for (const item of items) {
                            itemSizes.push(item.clientHeight)
                        }

                        columItemSizes.push(itemSizes)
                        itemSizes = []
                    }
                    
                    if (columItemSizes[0].length === columItemSizes[1].length) {
                        this.applySameSizes(columns, columItemSizes, columItemSizes[0].length)
                    }
                }
            }   
        },
        applySameSizes: function (columns, columItemSizes, columnItemsLength) {
            for (let i = 0; i < columnItemsLength; i++) { 
                if (columItemSizes[0][i] === columItemSizes[1][i]) {
                    continue
                }
        
                let smallerItemIndex = columItemSizes[0][i] > columItemSizes[1][i] 
                                        ? 1 
                                        : 0
                                        
                let biggerItemHeight = columItemSizes[0][i] > columItemSizes[1][i] 
                                        ? columItemSizes[0][i] 
                                        : columItemSizes[1][i]
        
                columns[smallerItemIndex].getElementsByClassName('item')[i].style.height = `${biggerItemHeight}px`;
            }
        }
    }

    if (
        document.getElementsByClassName('why-luxoft-two-columns').length > 0 &&
        window.innerWidth > 903
    ) {
        whyLuxoftTwoColumns.init()
    }
}())
