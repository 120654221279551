const excludedEmails = ['gmail.com',
                        'yahoo.com',
                        'hotmail.com',
                        'icloud.com',
                        'thunderbird',
                        'aol.com',
                        'outlook.com',
                        'talktalk.co.uk',
                        'cheerful.com',
                        'engineer.com',
                        'dr.com',
                        'writeme.com',
                        'iname.com',
                        'asia.com',
                        'europe.com',
                        'post.com',
                        'consultant.com',
                        'myself.com',
                        'usa.com',
                        'email.com',
                        'mail.com']

const formsToValidateEmails = [1971, 2143, 2012, 2146, 2174, 2158, 2155, 2160, 2185, 2193, 2175, 2234, 2246, 2244, 2250,
                                2252, 2254, 2256, 2258, 2260, 2262, 2264, 2267, 2276, 2300, 2320, 2321, 2100, 2328, 2327, 2330, 2342, 2343,
                                2354, 2355, 2351, 2365, 2369]

/**
 * Marketo Pop-up forms
 */
if (document.querySelector('.open-marketo-form')) {
    const openMarketoFormBtn = document.getElementsByClassName('open-marketo-form')

    for (const button of openMarketoFormBtn) {
        button.addEventListener('click', event => {
            event.preventDefault()

            const marketoFormId = parseInt(button.dataset.marketoId)

            MktoForms2.loadForm("//offer.luxoft.com", "170-LIB-021", marketoFormId, function(form) {
                MktoForms2.lightbox(form).show()

                if (marketoFormId === 2160) {
                    const cesForm = document.getElementById('mktoForm_2160')
                    if (cesForm) {
                        cesForm.classList.add('topic-of-interest-full-w')
                    }
                }

                if (document.getElementById('read-button')) {
                    setTimeout(() => {
                        const readTermsBtn = document.getElementById('read-button')
                        const termsText = document.getElementById('read-text')
                        readTermsBtn.addEventListener('click', event => {
                            event.preventDefault()
                            if (termsText.classList.contains('hidden')) {
                                termsText.style.display = 'inline-block'
                                termsText.classList.remove('hidden')
                            } else {
                                termsText.style.display = 'none'
                                termsText.classList.add('hidden')
                            }
                        })
                    }, 1500)
                }

                if (document.getElementById('read-button-persona-data')) {
                    setTimeout(() => {
                        const readPersonalBtn = document.getElementById('read-button-persona-data')
                        const readPersonalTxt = document.getElementById('read-text-personal-data')
                        readPersonalBtn.addEventListener('click', event => {
                            event.preventDefault()
                            if (readPersonalTxt.classList.contains('hidden')) {
                                readPersonalTxt.style.display = 'inline-block'
                                readPersonalTxt.classList.remove('hidden')
                            } else {
                                readPersonalTxt.style.display = 'none'
                                readPersonalTxt.classList.add('hidden')
                            }
                        })
                    }, 1500)
                }

                let form2065Failed = false

                form.onValidate(function() {
                    const values = form.vals()

                    if (marketoFormId === 2065) {
                        if (
                            (values.iBM_Email_Opt_In === 'yes' || values.iBM_Email_Opt_Out === 'yes') &&
                            (values.iBM_Phone_Opt_In === 'yes' || values.IBM_Phone_Opt_Out === 'yes')
                            ) {
                                form.submitable(true)
                        } else if (
                            values.iBM_Phone_Opt_In === 'yes' &&
                            values.iBM_Email_Opt_In === 'yes'
                            ) {
                                form.submitable(true)
                        } else if (
                            values.iBM_Email_Opt_Out === 'yes' &&
                            values.IBM_Phone_Opt_Out === 'yes'
                            ) {
                                form.submitable(true)
                        } else {
                            if (!form2065Failed) {
                                const mktoOffset  = document.getElementsByClassName('mktoFieldWrap')
                                const lastOffset = mktoOffset[mktoOffset.length - 1]
                                const span = document.createElement('span')
                                span.textContent = 'Choose at least one "by e-mail" option and one "by telephone" option.'
                                span.className = 'marketo-form-alert'
                                lastOffset.insertBefore(span, lastOffset.firstChild);
                            }

                            form2065Failed = true
                            form.submitable(false)
                        }
                    }

                    if (
                        values.Email &&
                        formsToValidateEmails.includes(marketoFormId)
                    ) {
                        const emailDomain = getDomainFromEmail(values.Email)

                        if (excludedEmails.includes(emailDomain)) {
                            document.getElementById('Email').classList.remove('mktoValid')
                            document.getElementById('Email').classList.add('mktoInvalid')
                            form.submitable(false)
                            const emailElem = form.getFormElem().find("#Email")
                            form.showErrorMessage("Please provide your work email.", emailElem)
                        } else {
                            form.submitable(true)
                        }
                    }
                })

                form.onSuccess(function() {
                    if (marketoFormId === 2141) {
                        const body = document.getElementsByTagName('body')
                        const mktoModal = document.createElement('div')
                        const mktoModalMask = document.createElement('div')
                        mktoModal.className = 'modal-message'
                        mktoModalMask.className = 'modal-message__container'
                        mktoModalMask.innerHTML = `<div class="modal-message__btn-container">
                                            <button type="button" id="close-thank-meesage">x</button>
                                            </div>
                                            <h4>Thank you!</h4><br><h5>YOUR REGISTRATION WAS SUCCESSFUL</h5>`
                        mktoModal.appendChild(mktoModalMask)
                        body[0].appendChild(mktoModal)

                        const closeMessage = document.getElementById('close-thank-meesage')
                        const modalMessage = document.getElementsByClassName('modal-message')

                        if (closeMessage && modalMessage.length > 0) {
                            closeMessage.addEventListener('click', event => {
                                modalMessage[0].classList.add('d-none')
                            })
                        }
                    }

                    //location.href = button.dataset.marketoRedirect
                    return false
                })
            })
        })
    }
}

function getDomainFromEmail(email) {
    return email.split('@')[1];
}

/**
 * Marketo rendered forms
 */
const marketoContainer = document.getElementById('render-marketo-form')
const urlParams = new URLSearchParams(window.location.search)

if (marketoContainer) {
    const formResultParam = urlParams.get('aliId')
    const marketoFormId = parseInt(marketoContainer.dataset.marketoId)

    if (formResultParam !== null) {
        marketoContainer.classList.add('d-none')

        const marketoMessage = document.getElementById('marketo-thank-you-message')
        if (marketoMessage) {
            marketoMessage.classList.remove('d-none')
        }

        return
    }

    MktoForms2.loadForm("//offer.luxoft.com", "170-LIB-021", marketoFormId, function(form) {

        if (form) {
            marketoContainer.append(form.render()[0])
        }

        form.onValidate(function() {
            const values = form.vals()

            if (
                values.Email &&
                formsToValidateEmails.includes(marketoFormId)
            ) {
                const emailDomain = getDomainFromEmail(values.Email)

                if (excludedEmails.includes(emailDomain)) {
                    document.getElementById('Email').classList.remove('mktoValid')
                    document.getElementById('Email').classList.add('mktoInvalid')
                    form.submitable(false)
                    const emailElem = form.getFormElem().find("#Email")
                    form.showErrorMessage("Please provide your work email.", emailElem)
                } else {
                    form.submitable(true)
                }
            }
        })

        if (document.getElementById('read-button')) {
            setTimeout(() => {
                const readTermsBtn = document.getElementById('read-button')
                const termsText = document.getElementById('read-text')
                readTermsBtn.addEventListener('click', event => {
                    event.preventDefault()
                    if (termsText.classList.contains('hidden')) {
                        termsText.style.display = 'inline-block'
                        termsText.classList.remove('hidden')
                    } else {
                        termsText.style.display = 'none'
                        termsText.classList.add('hidden')
                    }
                })
            }, 1500)
        }

        if (document.getElementById('read-button-persona-data')) {
            setTimeout(() => {
                const readPersonalBtn = document.getElementById('read-button-persona-data')
                const readPersonalTxt = document.getElementById('read-text-personal-data')
                readPersonalBtn.addEventListener('click', event => {
                    event.preventDefault()
                    if (readPersonalTxt.classList.contains('hidden')) {
                        readPersonalTxt.style.display = 'inline-block'
                        readPersonalTxt.classList.remove('hidden')
                    } else {
                        readPersonalTxt.style.display = 'none'
                        readPersonalTxt.classList.add('hidden')
                    }
                })
            }, 1500)
        }
    })
}

