let geoLocations = []
const modalLocation = document.getElementById('modal-location')

/** these are for contact us page */
const allGeoCoordinates = document.getElementsByClassName('coordinates')

/** countryBtns is for global presence page */
const countryBtns = document.getElementsByClassName('country-btn')

for (const btn of countryBtns) {
    btn.addEventListener('click', e => {
        e.preventDefault()

        const countryName = btn.dataset.country
        updateContent(btn, countryName)
    })
}

if (allGeoCoordinates.length > 0) {
    getCoordinatesFromList(allGeoCoordinates)
}

/** locationsSelect is for global presence */
if (document.getElementById('locations-select')) {
    const locationSelect = document.getElementById('locations-select')
    locationSelect.addEventListener('change', e => {
        const locationName = locationSelect.options[locationSelect.selectedIndex].value

        if (locationName === '') return

        const selectedLocation = document.querySelectorAll(`.${locationName}`)
        const locationContentContainer = document.getElementById('location-content')
        locationContentContainer.style.opacity = 0

        if (selectedLocation) {
            getSingleCoordinate(selectedLocation)
        }

        if (selectedLocation.length > 1) {
            let htmlContent = ''

            for (const item of selectedLocation) {
                htmlContent+= item.dataset.content
            }

            locationContentContainer.innerHTML = htmlContent
        } else {
            locationContentContainer.innerHTML = selectedLocation[0].dataset.content
        }

        setTimeout(() => {
            locationContentContainer.style.opacity = 1
        }, 310)


    })
}

/** locationsSelect is for global presence */
if (document.getElementById('locations-select-mobile')) {
    const countriesSelect = document.getElementById('locations-select-mobile')
    countriesSelect.addEventListener('change', e => {
        const countryName = countriesSelect.options[countriesSelect.selectedIndex].value

        if (countryName === '') return

        updateContent(null, countryName)
    })
}

/** locationsSelect is for contact us page */
if (document.getElementById('locations-list')) {
    const locationsSelect = document.getElementById('locations-list')
    locationsSelect.addEventListener('change', e => {
        const locationValue = locationsSelect.options[locationsSelect.selectedIndex].value

        if (locationValue === '') return

        const className = locationValue.trim()
        const allOfficces = document.getElementsByClassName('location-offices')
        const listOfOfficces = document.getElementsByClassName(`location-offices ${className}`)
        const coordinatesByCity = document.getElementsByClassName(`coordinates ${className}`)

        if (allOfficces.length > 0) {
            for (const shownOfficce of allOfficces){
                shownOfficce.classList.remove('show')
            }
        }

        if (listOfOfficces.length > 0) {
            for (const office of listOfOfficces){
                office.classList.add('show')
            }
        }

        if (coordinatesByCity.length > 0) {
            getCoordinatesFromList(coordinatesByCity)
        }
    })
}

function getSingleCoordinate(locationGeo) {
    geoLocationValues = []

    if (locationGeo.length !== undefined) {
        for (const geo of locationGeo) {
            let locationGeoLocation = geo.dataset.geo.substring(0, geo.dataset.geo.length -2)
            locationGeoLocation = locationGeoLocation.substring(2)
            let thisGeoObject = JSON.parse(locationGeoLocation)

            if (
                thisGeoObject.lat !== ''
                && thisGeoObject.long !== ''
            ) {
                geoLocationValues.push({'name': '', 'geo': `${thisGeoObject.lat},${thisGeoObject.long}`})
            }
        }
    } else {
        let locationGeoLocation = locationGeo.dataset.geo.substring(0, locationGeo.dataset.geo.length -2)
        locationGeoLocation = locationGeoLocation.substring(2)
        let thisGeoObject = JSON.parse(locationGeoLocation)

        if (
            thisGeoObject.lat !== ''
            && thisGeoObject.long !== ''
        ) {
            geoLocationValues.push({'name': '', 'geo': `${thisGeoObject.lat},${thisGeoObject.long}`})
        }
    }

    if (geoLocationValues.length > 0) {
        initGoogleMap(true, geoLocationValues)
    }
}

function getCoordinatesFromList(listOfCoordinates, country = null) {
    geoLocations = []
    for (const geo of listOfCoordinates) {
        let thisGeoLocation = geo.dataset.geo.substring(0, geo.dataset.geo.length -2)
        thisGeoLocation = thisGeoLocation.substring(2)
        let thisGeoObject = JSON.parse(thisGeoLocation)

        if (
            thisGeoObject.lat !== ''
            && thisGeoObject.long !== ''
        ) {
            geoLocations.push({'name': '', 'geo': `${thisGeoObject.lat},${thisGeoObject.long}`})
        }
    }

    if (geoLocations.length > 0) {
        initGoogleMap(true, geoLocations, country)
    }
}

function updateContent(btn = null, countryName) {
  if (document.getElementsByClassName('country-btn active').length > 0) {
      document.getElementsByClassName('country-btn active')[0].classList.remove('active')
  }

  if (btn) btn.classList.add('active')

  const currentCountry = document.getElementById('country-name')
  currentCountry.style.opacity = 0

  const activeDescriptions = document.getElementsByClassName('country-description active')
  if (activeDescriptions.length > 0) {
      for (const activeDescription of activeDescriptions) {
          activeDescription.style.opacity = 0
          setTimeout(() => {
              activeDescription.classList.remove('active')
          }, 300)
      }
  }

  const countryLocations = document.querySelectorAll(`#cities-${countryName} ul li`)
  const locationsSelect = document.getElementById('locations-select')
  document.querySelectorAll('#locations-select option').forEach((option) => {
      if (!option.textContent.includes('Choose')) {
          option.remove()
      }
  })

  if (countryLocations.length > 0) {
      getCoordinatesFromList(countryLocations, countryName)
  }

  const citiesAdded = []

  for (const location of countryLocations) {
      if (!citiesAdded.includes(location.textContent.trim())) {
          const option = document.createElement("option")
          option.value = location.className
          option.text = location.textContent.trim()
          locationsSelect.appendChild(option)
          citiesAdded.push(location.textContent.trim())
      }
  }

  const locationContentContainer = document.getElementById('location-content')

  locationContentContainer.style.opacity = 0

  setTimeout(() => {
    locationContentContainer.innerHTML = ''
    currentCountry.textContent = countryName.replace('-',' ')
    currentCountry.style.opacity = 1

    if (document.getElementById(`country-content-${countryName}`)) {
        document.getElementById(`country-content-${countryName}`).style.opacity = 1
        document.getElementById(`country-content-${countryName}`).classList.add('active')
    }

    if (document.getElementById(`country-content-mobile-${countryName}`)) {
        document.getElementById(`country-content-mobile-${countryName}`).style.opacity = 1
        document.getElementById(`country-content-mobile-${countryName}`).classList.add('active')
    }
  }, 310)
}

function loadInitialLocationData() {
  const urlParams = new URLSearchParams(window.location.search)
  const tab = urlParams.get('tab') || ''

  if (tab === 'asia-pacific') {
    const collapse2 = document.getElementById('collapse2')
    const firstLiElement = collapse2.getElementsByTagName('li')[0]
    const countryName = firstLiElement.getElementsByTagName('a')[0].dataset.country
    const countryLiElement = document.getElementById(`element-${countryName}`)
    const countryBtn = countryLiElement.getElementsByTagName('a')[0]
    updateContent(countryBtn, countryName)
  } else if (tab === 'americas') {
    const collapse1 = document.getElementById('collapse1')
    const firstLiElement = collapse1.getElementsByTagName('li')[0]
    const countryName = firstLiElement.getElementsByTagName('a')[0].dataset.country
    const countryLiElement = document.getElementById(`element-${countryName}`)
    const countryBtn = countryLiElement.getElementsByTagName('a')[0]
    updateContent(countryBtn, countryName)
  } else {
    const countryLiElement = document.getElementById('element-Switzerland')
    const countryBtn = countryLiElement.getElementsByTagName('a')[0]
    updateContent(countryBtn, 'Switzerland')

    setTimeout(() => {
      const selectElement = document.getElementById('locations-select')
      selectElement.selectedIndex = 1
      const event = new Event('change')
      selectElement.dispatchEvent(event)
    }, 350)
  }
}

function initGoogleMap(btnAction, geoCoordinates, country = null) {
    let bounds = new google.maps.LatLngBounds()

    if (btnAction) geoLocations = geoCoordinates

    if (geoLocations.length === 0) return

    const centerCoordinates = geoLocations[0]['geo'].split(',')

    const mapElement = document.getElementById('map')
    const mapOptions = {
        zoom: 11,
        center: new google.maps.LatLng(centerCoordinates[0], centerCoordinates[1]),
        styles: [{
            "featureType": "administrative",
            "elementType": "all",
            "stylers": [{
                "saturation": "-100"
            }]
        }, {
            "featureType": "administrative.province",
            "elementType": "all",
            "stylers": [{
                "visibility": "off"
            }]
        }, {
            "featureType": "landscape",
            "elementType": "all",
            "stylers": [{
                "saturation": -100
            }, {
                "lightness": 65
            }, {
                "visibility": "on"
            }]
        }, {
            "featureType": "poi",
            "elementType": "all",
            "stylers": [{
                "saturation": -100
            }, {
                "lightness": "50"
            }, {
                "visibility": "simplified"
            }]
        }, {
            "featureType": "road",
            "elementType": "all",
            "stylers": [{
                "saturation": "-100"
            }]
        }, {
            "featureType": "road.highway",
            "elementType": "all",
            "stylers": [{
                "visibility": "simplified"
            }]
        }, {
            "featureType": "road.arterial",
            "elementType": "all",
            "stylers": [{
                "lightness": "30"
            }]
        }, {
            "featureType": "road.local",
            "elementType": "all",
            "stylers": [{
                "lightness": "40"
            }]
        }, {
            "featureType": "transit",
            "elementType": "all",
            "stylers": [{
                "saturation": -100
            }, {
                "visibility": "simplified"
            }]
        }, {
            "featureType": "water",
            "elementType": "geometry",
            "stylers": [{
                "hue": "#ffff00"
            }, {
                "lightness": -25
            }, {
                "saturation": -97
            }]
        }, {
            "featureType": "water",
            "elementType": "labels",
            "stylers": [{
                "lightness": -25
            }, {
                "saturation": -100
            }]
        }]
    }



    const map = new google.maps.Map(mapElement, mapOptions)

    for (let i = 0; i < geoLocations.length; ++i) {
        const geoCoordinates = geoLocations[i]['geo'].split(',')
        const marker = new google.maps.Marker({
            position: new google.maps.LatLng(geoCoordinates[0], geoCoordinates[1]),
            map: map,
            title: geoLocations[i]['name'],
            icon: '/theme/luxoft/assets/images/icons/marker.png'
        })

        bounds.extend(marker.position)
    }
    map.setCenter(bounds.getCenter())

    map.fitBounds(bounds)

    const listener = google.maps.event.addListener(map, "idle", function() {

        const mapDim = {
            height: mapElement.clientHeight,
            width: mapElement.clientWidth
        }

        let zoom = getBoundsZoomLevel(bounds, mapDim)

        if (zoom > 15) {
            zoom = 15
        } else if (geoLocations.length > 20) {
            zoom = 2
        } else if (geoLocations.length > 1) {
            zoom = 6

            if (
                country === 'USA' ||
                country === 'China' ||
                country === 'India' ||
                country === 'Russia'
            ) {
                zoom = 4
            }
        }

        map.setZoom(zoom)
        google.maps.event.removeListener(listener)
    })
}

function getBoundsZoomLevel(bounds, mapDim) {
    var WORLD_DIM = {
        height: 256,
        width: 256
    }

    var ZOOM_MAX = 21

    function latRad(lat) {
        var sin = Math.sin(lat * Math.PI / 180)
        var radX2 = Math.log((1 + sin) / (1 - sin)) / 2
        return Math.max(Math.min(radX2, Math.PI), -Math.PI) / 2
    }

    function zoom(mapPx, worldPx, fraction) {
        return Math.floor(Math.log(mapPx / worldPx / fraction) / Math.LN2)
    }

    var ne = bounds.getNorthEast()
    var sw = bounds.getSouthWest()

    var latFraction = (latRad(ne.lat()) - latRad(sw.lat())) / Math.PI

    var lngDiff = ne.lng() - sw.lng()
    var lngFraction = ((lngDiff < 0) ? (lngDiff + 360) : lngDiff) / 360

    var latZoom = zoom(mapDim.height, WORLD_DIM.height, latFraction)
    var lngZoom = zoom(mapDim.width, WORLD_DIM.width, lngFraction)

    return Math.min(latZoom, lngZoom, ZOOM_MAX)
}

if (window.location.pathname === '/global-presence') {
  loadInitialLocationData()
}
