$(".down-arr").click(function () {
  const parent = $(".down-arr").parent();
  $("html, body").animate(
    {
      scrollTop: parent.hasClass("container-hero-short-purple")
        ? parent[0].offsetHeight
        : parent[0].offsetHeight - $("header")[0].offsetHeight,
    },
    "slow"
  );
  return false;
});

var carouselHero = document.getElementById("carouselHero");

if (carouselHero) {
  carouselHero.addEventListener("slide.bs.carousel", function (e) {
    var tabsList = document.querySelectorAll(
      ".enhanced-carousel .carousel-indicators div.tab a"
    );

    tabsList.forEach((element) => {
      if (element.dataset.bsSlideTo == e.to) {
        element.classList.add("active");
      } else {
        element.classList.remove("active");
      }
    });
  });
}
