<template>
    <section class="luxoft-booth">
        <div class="container">
            <div class="row">
                <div class="col-12 col-lg-5 col-xl-4 luxoft-booth__header">
                    <p class="luxoft-booth__header--first-text">
                        {{ content[version]['TEXT-SECTION-1'] }}
                    </p>
                    <h1>
                        {{ content[version]['TEXT-SECTION-2'] }}
                    </h1>
                    <p class="mt-3">
                        {{ content[version]['TEXT-SECTION-3'] }}
                    </p>
                    <div
                        class="luxoft-booth__cards-content desktop"
                        :class="selectedItem === 'initial' ? 'initial' : ''"
                    >
                        <div v-if="selectedItem === 'initial'" class="initial-card">
                            <p>
                                {{ content[version]['TEXT-SECTION-4'] }}
                            </p>
                        </div>
                        <div v-else class="cards-with-content">
                            <div class="single-card">
                                <img :src="content['EN'][selectedItem]['icon']" alt="item icon" class="mb-2">
                                <b class="mb-1">{{ content[version][selectedItem]['title'] }}</b>
                                <p>{{ content[version][selectedItem]['text'] }}</p>
                            </div>
                        </div>
                        <div class="asd">
                            <a :href="content[version]['LINK-TO-PAGE']" class="btn btn-outline">
                                {{ content[version]['TEXT-BTN'] }}
                            </a>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-lg-7 col-xl-8">
                    <div class="luxoft-booth__image-wrapper">
                        <div class="single-circle tap-circle" @click="selectedItem = 'TAP'" :class="selectedItem === 'TAP' ? 'active' : ''">
                            <div class="hover"></div>
                        </div>
                        <div class="single-circle end-to-end-circle" @click="selectedItem = 'END-TO-END'" :class="selectedItem === 'END-TO-END' ? 'active' : ''">
                            <div class="hover"></div>
                        </div>
                        <div class="single-circle ivi-circle" @click="selectedItem = 'IVI'" :class="selectedItem === 'IVI' ? 'active' : ''">
                            <div class="hover"></div>
                        </div>
                        <div class="single-circle uxd-circle" @click="selectedItem = 'UXD'" :class="selectedItem === 'UXD' ? 'active' : ''">
                            <div class="hover"></div>
                        </div>
                        <div class="single-circle oas-circle" @click="selectedItem = 'OAS'" :class="selectedItem === 'OAS' ? 'active' : ''">
                            <div class="hover"></div>
                        </div>
                        <img src="/theme/luxoft/assets/images/custom-pages/luxoft-booth/image-full-station.jpg" alt="luxoft-booth image" class="main-image">
                    </div>
                </div>
                <div class="col-12 luxoft-booth__cards-content mobile mt-5">
                    <div v-if="selectedItem === 'initial'" class="initial-card">
                        <p>
                            {{ content[version]['TEXT-SECTION-4'] }}
                        </p>
                    </div>
                    <div v-else class="cards-with-content">
                        <div class="single-card">
                            <img :src="content['EN'][selectedItem]['icon']" alt="item icon" class="mb-2">
                            <b class="mb-1">{{ content[version][selectedItem]['title'] }}</b>
                            <p>{{ content[version][selectedItem]['text'] }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script setup>
import { ref } from 'vue'

const selectedItem = ref('initial')
const version = (window.location.pathname === '/autochina2024-luxoft-booth') ? 'EN' : "CN"
const content = {
    "EN": {
        "TEXT-SECTION-1": 'Luxoft booth at Auto China 2024',
        "TEXT-SECTION-2": 'Your software-defined vehicle transformation partner',
        "TEXT-SECTION-3": 'Check out some of our capabilities and then join us in person at the old CIEC venue (booth 1A15).',
        "TEXT-SECTION-4": 'Click on the circles to learn more about each demo station.',
        "TEXT-BTN": '中文',
        "LINK-TO-PAGE": '/autochina2024-luxoft-booth-cn',
        "TAP": {
            "icon": '/theme/luxoft/assets/images/custom-pages/luxoft-booth/01-testing-icon.svg',
            "title": 'Testing Automation Platform',
            "text": '— testing at scale; 24/7 remote, automated testing. Run test cases from anywhere in the world with one click'
        },
        "END-TO-END": {
            "icon": '/theme/luxoft/assets/images/custom-pages/luxoft-booth/02-ad-icon.svg',
            "title": 'Bringing AD end-to-end software development to life',
            "text": '— use digital models of complex systems to design, test and improve self-driving technology more efficiently'
        },
        "IVI": {
            "icon": '/theme/luxoft/assets/images/custom-pages/luxoft-booth/04-ivi-icon.svg',
            "title": 'IVI platform',
            "text": '— see a fully-fledged next-generation IVI platform on a pillar-to-pillar display showcasing the high-end SoC platform capability of AMD'
        },
        "UXD": {
            "icon": '/theme/luxoft/assets/images/custom-pages/luxoft-booth/05-uxd-icon.svg',
            "title": 'UXD',
            "text": '— beyond what you see overseas — elevate your global success with Luxoft\'s tailored UXD, meticulously crafted through in-depth research, local knowledge, and seamless collaboration with our European and US design experts and strategic eco-partnerships'
        },
        "OAS": {
            "icon": '/theme/luxoft/assets/images/custom-pages/luxoft-booth/06-overseas-icon.svg',
            "title": 'Overseas adoption solutions',
            "text": '— harness our European resources, regulatory prowess and extensive road-testing experience to drive your expansion into the European market'
        }
    },
    "CN": {
        "TEXT-SECTION-1": 'Luxoft 在2024北京国际汽车展览会上的展位信息',
        "TEXT-SECTION-2": '您的软件定义车辆转型合作伙伴',
        "TEXT-SECTION-3": '了解部分我们的能力，然后来中国国际展览中心（老馆）会场（展位1A15）加入我们。',
        "TEXT-SECTION-4": '点击图上的圆圈了解更多关于演示站的信息。',
        "TEXT-BTN": 'English',
        "LINK-TO-PAGE": '/autochina2024-luxoft-booth',
        "TAP": {
            "title": '测试自动化平台',
            "text": '— 大规模24小时全天候远程自动化测试。 可实现在世界任何地方实现一键式运行测试解决方案。'
        },
        "END-TO-END": {
            "title": '自动驾驶(AD)的需求到代码的端对端软件开发',
            "text": '— 通过智慧的使用模型化技术，更加高效地设计、测试和改进自动驾驶'
        },
        "IVI": {
            "title": '车载信息娱乐系统(IVI)平台',
            "text": '— 在pillar-to-pillar的显示屏上看到一个成熟的IVI平台，展示AMD的高端芯片系统 (SoC)平台能力'
        },
        "UXD": {
            "title": '用户体验设计',
            "text": '— 更是量身定制的用户体验。我们通过深入针对性研究、本地化适配、与全球设计专家以及战略生态合作伙伴的紧密合作，精心打造用户体验，提升您的全球化竞争力，。'
        },
        "OAS": {
            "title": '品牌出海解决方案',
            "text": '— 利用我们的欧洲资源、监管能力和丰富的路测经验，助力您向欧洲市场的拓展'
        }
    }
}
</script>
