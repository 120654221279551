<template>
    <div class="col-12 col-lg-6">
        <div class="card border-0 mb-3 myblogs__card">
            <img class="card-img" 
                :src="(blog.fieldValues.photo.extension === '') ? 'https://dummyimage.com/700x420/000/fff' : blog.fieldValues.photo.path"
                :alt="blog.fieldValues.photo.alt + ' | ' + blog.extras.title"
            >
            <div class="card-body">
                <p class="myblogs__card-title" v-html="blog.fieldValues.title.en" />
                <p class="myblogs__card-text">
                    {{ blog.authorName }}
                </p>
            </div>
            <div class="card-body">
                <div class="row">
                    <div class="col-7 text-left">
                        <div>
                            <a class="btn btn-text btn-icon focus" :href="blog.extras.link">
                                <div class="text-container">
                                    <div class="text stretched-link">READ MORE</div>
                                    <div class="arr-offering">
                                        <i class="arr-small one"></i>
                                        <i class="arr-small two"></i>
                                        <i class="arr-small three"></i>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>
                    <div class="col-5 text-end">
                        <p class="myblogs__card-footer">
                            <strong>
                            {{ applyFormat(blog.publishedAt) }}
                            </strong>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import formatDate from '../composables/formatDate'

const props = defineProps({
    blog: {
        type: Object,
        required: true,
        default: () => [],
    }
})

const applyFormat = formatDate()
    
</script>