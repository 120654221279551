const carouselWithControls = document.getElementById('carouselHeroWithControls')
const carouselHeroWithIndicators = document.getElementById('carouselHeroWithIndicators')

if (carouselWithControls) {
    new bootstrap.Carousel(carouselWithControls, {
        wrap: false
    })

    const carouselItems = carouselWithControls.getElementsByClassName('carousel-item')
    if (carouselItems.length > 1) {
        const lasItemPosition = carouselItems.length - 1
        const prevBtn = carouselWithControls.getElementsByClassName('carousel-control-prev')
        const nextBtn = carouselWithControls.getElementsByClassName('carousel-control-next')

        if (carouselItems[0].classList.contains('active')) {
            prevBtn[0].classList.add('disable')
        }
    
        carouselWithControls.addEventListener('slid.bs.carousel', event => {
            if (carouselItems[0].classList.contains('active')) {
                prevBtn[0].classList.add('disable')
            } else {
                prevBtn[0].classList.remove('disable')
            }
    
            if (carouselItems[lasItemPosition].classList.contains('active')) {
                nextBtn[0].classList.add('disable')
            } else {
                nextBtn[0].classList.remove('disable')
            }
        })
    }
}


if (carouselHeroWithIndicators) {
    const playPauseBtn = document.getElementById('play-pause-btn')
    const carouselIndicators = carouselHeroWithIndicators.getElementsByClassName('carousel-indicators')
    const previousContainer = carouselHeroWithIndicators.getElementsByClassName('previous-container')
    const nextContainer = carouselHeroWithIndicators.getElementsByClassName('next-container')
    const carouselInner = carouselHeroWithIndicators.getElementsByClassName('carousel-inner')
    const carousel = new bootstrap.Carousel(carouselHeroWithIndicators)
    const indicators = document.querySelectorAll('.carousel-indicators a')
    let carouselPausedWithBtn = false

    carouselInner[0].addEventListener('mouseenter', function() {
        if (!carouselPausedWithBtn) {
            if (playPauseBtn.classList.contains('btn-pause-icon')) {
                playPauseBtn.classList.remove('btn-pause-icon')
                playPauseBtn.classList.add('btn-play-icon')
            }
        }
    })

    carouselInner[0].addEventListener('mouseleave', function() {
        if (!carouselPausedWithBtn) {
            if (playPauseBtn.classList.contains('btn-play-icon')) {
                playPauseBtn.classList.remove('btn-play-icon')
                playPauseBtn.classList.add('btn-pause-icon')
            }
        }
    })

    carouselIndicators[0].addEventListener('mouseenter', function() {
        if (!carouselPausedWithBtn) {
            carousel.cycle()
        }
    })
    
    previousContainer[0].addEventListener('mouseenter', function() {
        if (!carouselPausedWithBtn) {
            carousel.cycle()
        }
    })

    nextContainer[0].addEventListener('mouseenter', function() {
        if (!carouselPausedWithBtn) {
            carousel.cycle()
        }
    })

    previousContainer[0].addEventListener('mouseleave', function() {
        if (!carouselPausedWithBtn) {
            carousel.pause()
        }
    })

    nextContainer[0].addEventListener('mouseleave', function() {
        if (!carouselPausedWithBtn) {
            carousel.pause()
        }
    })

    carouselHeroWithIndicators.addEventListener('slide.bs.carousel', () => {
        if (carouselPausedWithBtn) {
            setTimeout(() => {
                Array.from(indicators).forEach(
                    (el) => {
                        el.classList.remove('paused')
    
                        if (el.classList.contains('active')) {
                            el.classList.add('paused')
                        }
                    }
                )
            }, 100)
        }
    })

    playPauseBtn.addEventListener('click', function() {
        if (!carouselPausedWithBtn) {
            this.classList.remove('btn-pause-icon')
            this.classList.add('btn-play-icon')
            carousel.pause()
            carouselPausedWithBtn = !carouselPausedWithBtn

            if (indicators.length > 1) {
                Array.from(indicators).forEach(
                    (el) => {
                        if (
                            el.classList.contains('active') &&
                            !el.classList.contains('paused')
                        ) {
                            el.classList.add('paused')
                        }
                    }
                )
            }

            return false
        }

        if (carouselPausedWithBtn) {
            this.classList.remove('btn-play-icon')
            this.classList.add('btn-pause-icon')
            carousel.cycle()
            carouselPausedWithBtn = !carouselPausedWithBtn

            if (indicators.length > 1) {
                Array.from(indicators).forEach(
                    (el) => {
                        if (
                            el.classList.contains('active') &&
                            el.classList.contains('paused')
                        ) {
                            el.classList.remove('paused')
                        }
                    }
                )
            }
        }
    })
}