import { createApp } from 'vue/dist/vue.esm-bundler'
import RegularContentTypes from './vue/views/RegularContentTypes.vue'
import PressReleases from './vue/views/PressReleases.vue'
import Events from './vue/views/Events.vue'
import InsightsHub from './vue/views/InsightsHub.vue'
import BcmInsights from './vue/views/BcmInsights.vue'
import AutomotiveInsights from './vue/views/AutomotiveInsights.vue'
import CisInsights from './vue/views/CisInsights.vue'
import Search from './vue/views/Search.vue'
import AsaServiceInsights from './vue/views/AsaServiceInsights.vue'
import Cloud from './vue/views/Cloud.vue'
import AdvancedAnalytics from './vue/views/AdvancedAnalytics.vue'
import SoftwareEngineering from './vue/views/SoftwareEngineering.vue'
import Regtech from './vue/views/Regtech.vue'
import Insurance from './vue/views/Insurance.vue'
import CoreBanking from './vue/views/CoreBanking.vue'
import LuxoftBooth from './vue/views/LuxoftBooth.vue'
import VueAwesomePaginate from "vue-awesome-paginate"
import "vue-awesome-paginate/dist/style.css"

const loadVueComponent = [
                            '/autochina2024-luxoft-booth-cn',
                            '/autochina2024-luxoft-booth',
                            '/case-studies',
                            '/blog',
                            '/videos',
                            '/pr',
                            '/search',
                            '/event',
                            '/insights',
                            '/insights/automotive',
                            '/insights/cross-industry-solutions',
                            '/insights/financial-services',
                            '/insights/financial-services/as-a-service',
                            '/insights/financial-services/cloud',
                            '/insights/financial-services/advanced-analytics',
                            '/insights/financial-services/insurance',
                            '/insights/financial-services/regtech',
                            '/insights/financial-services/software-engineering',
                            '/insights/financial-services/core-banking',
                        ]

if (loadVueComponent.includes(window.location.pathname)) {
    const app = createApp({})
    app.component('RegularContentTypes', RegularContentTypes)
    app.component('PressReleases', PressReleases)
    app.component('Events', Events)
    app.component('InsightsHub', InsightsHub)
    app.component('BcmInsights', BcmInsights)
    app.component('AutomotiveInsights', AutomotiveInsights)
    app.component('CisInsights', CisInsights)
    app.component('AsaServiceInsights', AsaServiceInsights)
    app.component('Cloud', Cloud)
    app.component('AdvancedAnalytics', AdvancedAnalytics)
    app.component('SoftwareEngineering', SoftwareEngineering)
    app.component('Regtech', Regtech)
    app.component('Insurance', Insurance)
    app.component('CoreBanking', CoreBanking)
    app.component('Search', Search)
    app.component('LuxoftBooth', LuxoftBooth)
    app.use(VueAwesomePaginate).mount('#app')
}
