const imagesWithMarkUp = []; 
const imageElements = document.getElementsByTagName('img');
const script = document.getElementById('imageMarkUpData');
const baseHost = window.location.origin;
if (imageElements.length > 0 && script){
    for (const img of imageElements){
        let src = img.getAttribute('data-src') ? img.getAttribute('data-src').split("\n").join("") : img.getAttribute('src');
        if (src !== "" && !src.includes('icon')){
            src = src.startsWith('/') ? (baseHost + src) : src;
            const item = {
                "@context":"https://schema.org/", 
                "@type":"ImageObject", 
                "contentUrl": src,  
                "creditText":"Luxoft", 
                "creator": 
                    {
                        "@type": "Organization", 
                        "name": "Luxoft"
                    }, 
                "copyrightNotice":"Luxoft"};
            imagesWithMarkUp.push(item) 
        }
    }
    script.append(JSON.stringify(imagesWithMarkUp))
}